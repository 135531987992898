import React from "react";
import Select from "react-select";
import styled from "styled-components";

// Define the type for a country option
export interface CountryOption {
  value: string;
  label: string;
}

interface CountrySelectorProps {
  selectedCountry: CountryOption | null;
  handleChange: (selected: CountryOption | null) => void;
}

// List of countries for the selector
const countryOptions: CountryOption[] = [
  { value: 'US', label: 'United States' },
  { value: 'CA', label: 'Canada' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'IN', label: 'India' },
  { value: 'AU', label: 'Australia' },
  { value: 'PK', label: 'Pakistan' },

  // Add more countries as needed
];

// Helper function to get a country option by its label
export const getCountryOptionByLabel = (label: string): CountryOption | null => {
  const country = countryOptions.find(option => option.label === label);
  return country || null;  // Return the found country or null if not found
};


const StyledSelectContainer = styled.div`
  width: 100%;
  margin: 20px 0;

  .react-select__control {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding-left: 8px;
    font-size: 14px;
    height: 40px;
    box-shadow: none;
    display: flex;
    align-items: center;
  }

  .react-select__placeholder {
    color: #999;
  }

  .react-select__value-container {
    padding: 0;
    margin: 0;
  }

  .react-select__indicators {
    padding-right: 8px;
  }

  .react-select__menu {
    background-color: #fff;
  }

  .react-select__option {
    padding: 10px;
  }

  .react-select__option--is-selected {
    background-color: #007BFF;
    color: white;
  }

  .react-select__option--is-focused {
    background-color: #e6f0ff;
  }
`;

const CountrySelector: React.FC<CountrySelectorProps> = ({ selectedCountry, handleChange }) => {
  return (
    <StyledSelectContainer>
      <Select
        value={selectedCountry}
        onChange={handleChange}
        options={countryOptions}
        classNamePrefix="react-select"
        placeholder="Select your country"
      />
    </StyledSelectContainer>
  );
};

export default CountrySelector;
