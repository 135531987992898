// src/features/boxDetailsSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../app/store";
import { Box, Item } from "../utils/boxTypes";
import axios from "axios";
import { getBalance } from "../features/userSlice";
import { handleLogoutUser } from "../utils/utility";
import { useNavigate } from "react-router-dom";
import { getVipBoxes } from "./vipSlice";

interface BoxDetailsState {
  selectedBox: Box | null;
  selectedBoxId: string | null;
  loading: boolean;
  isLoadingWinningItem: boolean;
  error: string | null | undefined;
  winningItem: Item | null;
}

const initialState: BoxDetailsState = {
  selectedBox: null,
  selectedBoxId: null,
  loading: false,
  isLoadingWinningItem: false,
  error: null,
  winningItem: null,
};

// Thunk to fetch box details from the API
export const fetchBoxDetails = createAsyncThunk<
  any, // The return type (can be more specific based on the response structure)
  string, // Argument type (boxId)
  {
    dispatch: AppDispatch; // Dispatch type
    state: RootState; // State type
    rejectValue: string; // Optional reject value type (string in this case)
  }
>(
  "boxDetails/fetchBoxDetails",
  async (boxId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const selectedBoxId = state.boxDetails.selectedBoxId;

      // Perform any necessary operations with the state
      if (selectedBoxId !== boxId) {
        // Assume `setBoxDetails` is an internal function or action you want to call
        setBoxDetails(boxId);
      }

      // Fetch the box details using axios (or your API module)
      const response = await axios.get(`/box?id=${boxId}`);

      // Return the fetched data
      return response.data;
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Call logout action
        return rejectWithValue("Unauthorized access - logging out");
      }

      // Handle error and use rejectWithValue for rejection
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("Failed to fetch box details");
      }
    }
  }
);

export const fetchItemForWinning = createAsyncThunk<
  Item, // Return type
  string, // Parameters type (boxId)
  { dispatch: AppDispatch; rejectValue: string } // ThunkAPI types
>(
  "boxDetails/fetchItemForWinning",
  async (boxId, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const selectedBoxId = state.boxDetails.selectedBox?.id;

      // Use the api module instead of axios directly
      const response = await axios.post(`/box/open?id=${boxId}`);
      await dispatch(getBalance()).unwrap();
      return response.data as Item;
    } catch (error: any) {
      // Use rejectWithValue to handle the rejection
      return rejectWithValue(error.message);
    }
  }
);

export const fetchItemForWinningForVipBox = createAsyncThunk<
  Item, // Return type
  string, // Parameters type (boxId)
  { dispatch: AppDispatch; rejectValue: string } // ThunkAPI types
>(
  "boxDetails/fetchItemForWinningForVipBox",
  async (boxId, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const selectedBoxId = state.boxDetails.selectedBox?.id;
      // Use the api module instead of axios directly
      const response = await axios.post(`/box/open_vip?id=${boxId}`);
      dispatch(getVipBoxes());
      return response.data as Item;
    } catch (error: any) {
      // Use rejectWithValue to handle the rejection
      return rejectWithValue(error.message);
    }
  }
);

const boxDetailsSlice = createSlice({
  name: "boxDetails",
  initialState,
  reducers: {
    setBoxDetails: (state, action: PayloadAction<string | null>) => {
      state.selectedBoxId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBoxDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBoxDetails.fulfilled,
        (state, action: PayloadAction<Box>) => {
          state.selectedBox = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchBoxDetails.rejected, (state, action) => {
        // Use a type assertion to handle the type
        const castAction = action as PayloadAction<string | undefined>;
        state.loading = false;
        state.error =
          castAction.payload || "An error occurred while fetching box details.";
      });

    builder
      .addCase(fetchItemForWinning.pending, (state) => {
        state.isLoadingWinningItem = true;
        state.error = null;
      })
      .addCase(
        fetchItemForWinning.fulfilled,
        (state, action: PayloadAction<Item>) => {
          state.winningItem = action.payload;
          state.isLoadingWinningItem = false;
        }
      )
      .addCase(fetchItemForWinning.rejected, (state, action) => {
        // Use a type assertion to handle the type
        const castAction = action as PayloadAction<string | undefined>;
        state.isLoadingWinningItem = false;
        state.error =
          castAction.payload ||
          "An error occurred while fetching winning item for box.";
      })
      .addCase(fetchItemForWinningForVipBox.pending, (state) => {
        state.isLoadingWinningItem = true;
        state.error = null;
      })
      .addCase(
        fetchItemForWinningForVipBox.fulfilled,
        (state, action: PayloadAction<Item>) => {
          state.winningItem = action.payload;
          state.isLoadingWinningItem = false;
        }
      )
      .addCase(fetchItemForWinningForVipBox.rejected, (state, action) => {
        // Use a type assertion to handle the type
        const castAction = action as PayloadAction<string | undefined>;
        state.isLoadingWinningItem = false;
        state.error =
          castAction.payload ||
          "An error occurred while fetching winning item for box.";
      });
  },
});

export default boxDetailsSlice.reducer;
export const selectSelectedBox = (state: RootState) =>
  state.boxDetails.selectedBox;
export const selectSelectedBoxId = (state: RootState) =>
  state.boxDetails.selectedBoxId;
export const selectLoading = (state: RootState) => state.boxDetails.loading;
export const selectError = (state: RootState) => state.boxDetails.error;
export const { setBoxDetails } = boxDetailsSlice.actions;
