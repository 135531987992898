import React, { useEffect, useState } from 'react';
import { FaPaypal } from 'react-icons/fa';
import styled from 'styled-components';
import Footer from './Footer';

// Container for the entire how it works section
const Container = styled.div`
  padding: 4rem 2rem;
  background-color: #0c0f16;
  color: white;
  text-align: center;
  position: relative;
`;

// Step container that holds a step's text and image
const StepContainer = styled.div`
  margin: 3rem 0;
  display: flex;
  align-items: flex-start; /* Align items to start to avoid overlap */
  justify-content: space-between;
  flex-direction: row;
  padding: 2rem;
  border: 1px solid #1a1f2a;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
  }

  position: relative; /* Position relative for absolute arrows */

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const HorizontalArrow = styled.img`
  width: 80%;      /* Make the width cover the container */
  height: auto;     /* Maintain aspect ratio */
  display: block;
  margin: 0 auto;   /* Center the SVG */
  object-fit: fill; /* Ensure it stretches if necessary */
`;

const VerticalArrow = styled.img`
  width: 80px;
  height: 80px;
  margin: 20px auto;
  display: block;

  @media (min-width: 769px) {
    display: none; /* Hide the down arrow on wide screens */
  }
`;

// Text inside each step
const TextContainer = styled.div`
  flex: 1;
  text-align: left;
  margin-right: 2rem;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1.5rem;
    text-align: center;
  }
`;

// Step image styling
const StepImage = styled.img<{ isFirst?: boolean }>`
  flex: 1;
  max-width: 250px; /* Decrease max-width for smaller images */
  width: 100%;      /* Maintain full width of the container */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: ${({ isFirst }) => (isFirst ? '0 1rem' : '0')}; /* Keep padding if first image */
  margin-right: 2rem; /* Add margin to create space between image and text */

  @media (max-width: 768px) {
    margin: 0 auto; /* Center the image horizontally on mobile */
    max-width: 80%; /* Reduce image size on mobile */
    margin-bottom: 1rem; /* Add space below the image */
  }
`;

// Styled component for h2
const StepTitle = styled.h2`
  height: auto; /* Set height to auto for better responsiveness */
  flex-grow: 1;
  font-family: Verdana;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #00ffd4;
  margin-bottom: 0.5rem; /* Moved here */
`;

// Styled component for h3 renamed to Heading
const Heading = styled.h3`
  height: auto; /* Set height to auto for better responsiveness */
  flex-grow: 1;
  font-family: Verdana;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-bottom: 1rem; /* Add margin-bottom */
`;

// Styled component for paragraph
const StyledParagraph = styled.p`
  height: auto; /* Set height to auto to prevent overlap */
  flex-grow: 1;
  font-family: Verdana;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a1e5e5;
  margin: 0; /* Reset margin */
  padding: 0.5rem 0; /* Add some padding for spacing */
`;

// Example icon usage in the Step 2 container
// const IconsContainer = styled.div`
//   margin-top: 1rem;
// `;


const IconsContainer = styled.div`
  width: 100%; /* Ensure it takes full width, but adjustable */
  max-width: 500px; /* Optional max width to limit the size */
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap; /* Allow icons to wrap into multiple rows */
  gap: 16px; /* Space between icons */
  row-gap: 16px; /* Space between rows of icons */
  justify-content: flex-start; /* Align icons to the start */
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center; /* Center icons on smaller screens */
  }
`;





const PayPalLogo = styled.img`
  width: 90.5px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
`;

const CardLogo = styled.img`
  width: 53.2px;
  height: 32px;
  flex-grow: 0;
  object-fit: contain;
`;

const DiorLogo = styled.img`
  width: 85.5px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
`;

const RolexLogo = styled.img`
  width: 64px;
  height: 32px;
  flex-grow: 0;
  object-fit: contain;
`;

const AppleLogo = styled.img`
  width: 26px;
  height: 32px;
  flex-grow: 0;
  object-fit: contain;
`;

const LouisVuittonLogo = styled.img`
  width: 32.3px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
`;

const GucciLogo = styled.img`
  width: 46.3px;
  height: 32px;
  flex-grow: 0;
  object-fit: contain;
`;

const AdidasLogo = styled.img`
  width: 47.5px;
  height: 32px;
  flex-grow: 0;
  object-fit: contain;
`;

const NikeLogo = styled.img`
  width: 56px;
  height: 20px;
  flex-grow: 0;
  object-fit: contain;
`;

const Spacer = styled.div`
  height: 100px;
`;


// Component
const HowItWorksPage: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  // Update mobile state on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Container>
        <StepContainer>
          <TextContainer>
            <StepTitle>Step 1</StepTitle>
            <Heading>Sign Up</Heading>
            <StyledParagraph>  Join us with your email in just seconds and unlock the excitement of unboxing amazing mystery boxes.</StyledParagraph>
          </TextContainer>
          <StepImage src="/assets/gifs/sign_in_comp.gif" alt="Sign Up" isFirst />
        </StepContainer>

        {!isMobile ? (
          <HorizontalArrow src="/assets/svg/arrow-left.svg" alt="Arrow Left" />
        ) : (
          <VerticalArrow src="/assets/svg/arrow-down.svg" alt="Arrow Down" />
        )}

        <StepContainer>
          <StepImage src="/assets/howitworks/frame-2882.png" alt="Top Up" />
          <TextContainer>
            <StepTitle>Step 2</StepTitle>
            <Heading>  Top up your account with credits</Heading>
            <StyledParagraph>Earn credits by completing achievements or purchase them directly from us. We offer a variety of payment options, including PayPal, card payments, and major local methods for your convenience.</StyledParagraph>
            <IconsContainer>
              <a href="/" target="_blank">
                <PayPalLogo
                  src="/assets/paypal.png"
                  srcSet="/assets/paypal@2x.png 2x, /assets/paypal@3x.png 3x"
                  alt="PayPal Logo"
                />
              </a>

              <a href="/" target="_blank">
                <CardLogo
                  src="/assets/howitworks/card.png"
                  srcSet="/assets/howitworks/card@2x.png 2x, /assets/howitworks/card@3x.png 3x"
                  alt="Card Logo"
                />
              </a>
            </IconsContainer>
          </TextContainer>
        </StepContainer>

        {!isMobile ? (
          <HorizontalArrow src="/assets/svg/arrow-right.svg" alt="Arrow Right" />
        ) : (
          <VerticalArrow src="/assets/svg/arrow-down.svg" alt="Arrow Down" />
        )}

        <StepContainer>
          <TextContainer>
            <StepTitle>Step 3</StepTitle>
            <Heading>Unbox the chaos and reveal your lottery of surprises</Heading>
            <StyledParagraph>Choose and open our mystery boxes, packed with authentic items. Get the chance to score rare pieces at a fraction of the price from top brands like:</StyledParagraph>
            <IconsContainer>
              <a href="/" target="_blank">
                <DiorLogo
                  src="/assets/howitworks/dior.png"
                  srcSet="/assets/howitworks/dior@2x.png 2x, /assets/howitworks/dior@3x.png 3x"
                  alt="PayPal Logo"
                />
              </a>

              <a href="/" target="_blank">

                <RolexLogo
                  src="/assets/howitworks/rolex-logo-1.png"
                  srcSet="/assets/howitworks/rolex-logo-1@2x.png 2x, /assets/howitworks/rolex-logo-1@3x.png 3x"
                  alt="PayPal Logo"
                />
              </a>

              <a href="/" target="_blank">

                <AppleLogo
                  src="/assets/howitworks/apple-logo-black-1.png"
                  srcSet="/assets/howitworks/apple-logo-black-1@2x.png 2x, /assets/howitworks/apple-logo-black-1@3x.png 3x"
                  alt="PayPal Logo"
                />
              </a>

              <a href="/" target="_blank">

                <LouisVuittonLogo
                  src="/assets/howitworks/louis-vuitton.png"
                  srcSet="/assets/howitworks/louis-vuitton@2x.png 2x, /assets/howitworks/louis-vuitton@3x.png 3x"
                  alt="PayPal Logo"
                />
              </a>

              <a href="/" target="_blank">

                <GucciLogo
                  src="/assets/howitworks/gucci-logo.png"
                  srcSet="/assets/howitworks/gucci-logo@2x.png 2x, /assets/howitworks/gucci-logo@3x.png 3x"
                  alt="PayPal Logo"
                />
              </a>

              <a href="/" target="_blank">

                <AdidasLogo
                  src="/assets/howitworks/adidas.png"
                  srcSet="/assets/howitworks/adidas@2x.png 2x, /assets/howitworks/adidas@3x.png 3x"
                  alt="PayPal Logo"
                />
              </a>


              <a href="/" target="_blank">

                <NikeLogo
                  src="/assets/howitworks/logo-nike-1.png"
                  srcSet="/assets/howitworks/logo-nike-1@2x.png 2x, /assets/howitworks/logo-nike-1@3x.png 3x"
                  alt="PayPal Logo"
                />
              </a>
            </IconsContainer>
          </TextContainer>
          <StepImage src="/assets/gifs/box_open_comp.gif"  alt="Unbox" />
        </StepContainer>


        {!isMobile ? (
          <HorizontalArrow src="/assets/svg/arrow-left.svg" alt="Arrow Right" />
        ) : (
          <VerticalArrow src="/assets/svg/arrow-down.svg" alt="Arrow Down" />
        )}

        <StepContainer>
          <StepImage src="/assets/gifs/box_open_exchange.gif" alt="Unbox" />
          <TextContainer>
            <StepTitle>Step 4</StepTitle>
            <Heading>Not satisfied? Let’s exchange</Heading>
            <StyledParagraph>While our boxes are packed with tons of cool stuff, but the personal  preferences vary. If you don't like the product you have drawn, you can  always exchange it for credits and open other mystery boxes instead.</StyledParagraph>
          </TextContainer>
        </StepContainer>

        {!isMobile ? (
          <HorizontalArrow src="/assets/svg/arrow-left.svg" alt="Arrow Right" />
        ) : (
          <VerticalArrow src="/assets/svg/arrow-down.svg" alt="Arrow Down" />
        )}

        <StepContainer>
          <TextContainer>
            <StepTitle>Step 5</StepTitle>
            <Heading>Ship your products</Heading>
            <StyledParagraph>You can comfortably have your unboxed products delivered to your  doorstep. We ship globally and attempt to keep shipping costs to a  minimum. Depending on your location, customers receive their products  within 7 days to 2 weeks.</StyledParagraph>
          </TextContainer>
          <StepImage src="/assets/gifs/ship_items.gif" alt="Unbox" />
        </StepContainer>

      </Container>
      <Footer />
      <Spacer />

    </>
  );
};

export default HowItWorksPage;
