import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { RootState } from '../app/store';

export interface Setting {
  id: string;           // Unique identifier for the setting
  setting: string;        // Holds the key value for setting
  value: string;     // Represents the value
  created_by: string;   // Holds the ID of the user who created it
}

// Define the initial state
interface SettingsState {
  settings: Setting[];
  fetchSettingsStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  fetchSettingsError: string | null;
  isFetchSettingInprogress: boolean;
}

const initialState: SettingsState = {
  settings: [],
  fetchSettingsStatus: 'idle',
  fetchSettingsError: null,
  isFetchSettingInprogress: false,
};

// Selector to check if settings are loaded
export const selectSettingsLoaded = (state: RootState) => {
  return state.settings.fetchSettingsStatus === 'succeeded';
};

// Async action to fetch banners
export const fetchSettings = createAsyncThunk<Setting[]>(
  'settings/fetchSettings',
  async () => {
    const params = new URLSearchParams();

    const response = await axios.get('/settings', { params });
    return response.data;
  }
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // Handle the fetch banners cases
    builder.addCase(fetchSettings.pending, (state) => {
      state.fetchSettingsStatus = 'loading';
      state.fetchSettingsError = null;
      state.isFetchSettingInprogress = true;
    });
    builder.addCase(fetchSettings.fulfilled, (state, action: PayloadAction<Setting[]>) => {
      state.fetchSettingsStatus = 'succeeded';
      state.settings = action.payload;
      state.isFetchSettingInprogress = false;
    });
    builder.addCase(fetchSettings.rejected, (state, action) => {
      state.fetchSettingsStatus = 'failed';
      state.fetchSettingsError = action.error.message || 'Failed to fetch banners';
      state.isFetchSettingInprogress = false;
    });
  },
});

export const { } = settingsSlice.actions;

export default settingsSlice.reducer;
