import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";

export interface Banner {
  id: string;           // Unique identifier for the banner
  image: string;        // Holds the image path or file name
  order_id: number;     // Represents the order number
  active: boolean;      // Indicates if the banner is active or not
  created_by: string;   // Holds the ID of the user who created it
}

// Define the initial state
interface BannerState {
  banners: Banner[];
  fetchBannersStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  fetchBannersError: string | null;
  isFetchBannersInprogress: boolean;
}

const initialState: BannerState = {
  banners: [],
  fetchBannersStatus: 'idle',
  fetchBannersError: null,
  isFetchBannersInprogress: false,
};

// Async action to fetch banners
export const fetchBanners = createAsyncThunk<Banner[], string>(
  'banners/fetchBanners',
  async (active: string) => {
    const params = new URLSearchParams();
    if (active !== 'all') {
      params.append('active', active);
    }

    const response = await axios.get('/banners', { params });
    return response.data;
  }
);

const bannerSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // Handle the fetch banners cases
    builder.addCase(fetchBanners.pending, (state) => {
      state.fetchBannersStatus = 'loading';
      state.fetchBannersError = null;
      state.isFetchBannersInprogress = true;
    });
    builder.addCase(fetchBanners.fulfilled, (state, action: PayloadAction<Banner[]>) => {
      state.fetchBannersStatus = 'succeeded';
      // Sort the banners by order_id before assigning to state
      const sortedBanners = action.payload.sort((a, b) => a.order_id - b.order_id);
      state.banners = sortedBanners;
      state.isFetchBannersInprogress = false;
    });
    builder.addCase(fetchBanners.rejected, (state, action) => {
      state.fetchBannersStatus = 'failed';
      state.fetchBannersError = action.error.message || 'Failed to fetch banners';
      state.isFetchBannersInprogress = false;
    });
  },
});

export const { } = bannerSlice.actions;

export default bannerSlice.reducer;
