import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import { getImageSource } from "../utils/utility";
import {
  SquareGrid,
  SquareBox,
  BoxContent,
  BoxImage,
} from "./DynamicBoxes";
import { styled } from "styled-components";
import { setBoxDetails } from "../features/boxDetailsSlice";
import { setSelectedLevelBoxWithStatus } from "../features/vipSlice";
import { useNavigate } from "react-router-dom";
import localization from "../services/localization";
import { getVipBoxes } from "../features/vipSlice";
import { LevelBoxWithStatus } from "../utils/boxTypes";

// New styled components for additional elements
// Define a custom prop interface
interface CheckMarkProps {
  isVisible: boolean;
}

// Apply the custom prop interface to the styled component
const CheckMark = styled.img<CheckMarkProps>`
  position: absolute;
  top: 9%;
  left: 5%;
  width: 15px;
  height: 15px;
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

const LockIcon = styled.img`
  width: 71.4%;
  object-fit: cover;
  position: absolute;
  top: 14%;
  left: 50%;
  transform: translateX(-50%);
`;

interface LevelTagProps {
  locked: boolean;
}

const LevelTag = styled.div<LevelTagProps>`
  position: absolute;
  top: 9%;
  right: 5%;
  color: #fff;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 4px;
  background-color: ${(props) => (props.locked ? '#333333' : 'transparent')};
`;

const LoadingMessage = styled.p`
  padding: 50px;
  text-align: center;
  color: #40a5c5;
`;

interface BoxTitleProps {
  isOpened: boolean;
}

export const BoxTitle = styled.h3<BoxTitleProps>`
  font-family: Verdana, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #80d5ff; /* Light blue color as in screenshot */
  background-color: ${(props) => (props.isOpened ? '#333333' : '#3a4000')}; /* Conditional background color */
  padding: 4px 8px; /* Padding around the text */
  border-radius: 4px; /* Rounded edges for the background */
  position: absolute;
  bottom: 5%; /* Position at the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  margin: 0; /* Remove default margin */
  width: 90%; /* Adjust width if necessary */
`;



export const BonusBoxes: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [hoveredBox, setHoveredBox] = useState<string | null>(null);

  const levelBoxes = useSelector((state: RootState) => state.vip.levelBoxes);

  const [boxCount, setBoxCount] = useState(2);
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getVipBoxes());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        setBoxCount(2);
      } else if (windowWidth < 768) {
        setBoxCount(3);
      } else if (windowWidth < 1024) {
        setBoxCount(4);
      } else {
        setBoxCount(6);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight);
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  const handleBoxClick = (levelBoxWithStatus: LevelBoxWithStatus) => {
    if(levelBoxWithStatus.status === "available" || levelBoxWithStatus.status === "opened"){
      let box = levelBoxWithStatus.box!;
      dispatch(setBoxDetails(box.id));
      dispatch(setSelectedLevelBoxWithStatus(levelBoxWithStatus));
      navigate(`/vips/${box.id}`);
    }
  };

  const bonusBoxes = () => {
    if (levelBoxes.length === 0) {
      return <LoadingMessage>{localization.no_favorite_boxes}</LoadingMessage>;
    } else {
      return (
        <SquareGrid columns={boxCount}>
          {levelBoxes.map((levelBox, index) => {
            const boxObject = levelBox.box!;
            const isOpened = levelBox.status === "opened";
            const isLocked = levelBox.status === "locked";

            console.log("Box:", levelBox); // Log box details
            console.log("Status:", levelBox.status); // Log status to verify

            return (
              <SquareBox
                key={index}
                onMouseEnter={() => setHoveredBox(boxObject.id ?? "")}
                onMouseLeave={() => setHoveredBox(null)}
                onClick={() => handleBoxClick(levelBox)}
              >
                <BoxContent>
                  {/* Check Mark if opened */}
                  <CheckMark
                    src="/assets/check_mark_icon.png" // Path to the check mark icon
                    alt="Opened"
                    isVisible={isOpened}
                  />

                  {/* Display Lock Icon if locked, otherwise show BoxImage */}
                  {isLocked ? (
                    <LockIcon src="/assets/lock@3x.png" alt="Locked" />
                  ) : (
                    <BoxImage
                      src={getImageSource(boxObject.picture)}
                      $isHovered={hoveredBox === boxObject.id}
                    />
                  )}

                  {/* Level Tag */}
                  <LevelTag locked={isLocked}>Level {levelBox.level}</LevelTag>

                  {/* Centered Box Name */}
                  <BoxTitle isOpened={isOpened || isLocked}>{levelBox.name}</BoxTitle>
                </BoxContent>
              </SquareBox>
            );
          })}
        </SquareGrid>
      );
    }
  };

  return <>{bonusBoxes()}</>;
};
