import React, { useState } from "react";
import styled from "styled-components";
import Footer from "./Footer";

// Define the primary color for the support page
const primaryColor = "#a1e0e5";

const SupportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000c0d;
  color: ${primaryColor};
  padding: 20px;  // Reduced padding
  min-height: 100vh;
`;

const SupportHeader = styled.h1`
  font-size: 30px; // Reduced font size
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px; // Reduced margin
  color: ${primaryColor};
`;

const SupportForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: #1a1a1a;
  padding: 15px;  // Reduced padding
  border-radius: 10px;
  border: 2px solid ${primaryColor};
`;

const Label = styled.label`
  font-size: 14px; // Slightly smaller font size
  margin-bottom: 6px; // Reduced margin
  color: ${primaryColor};
`;

const Input = styled.input`
  padding: 8px; // Reduced padding for compactness
  margin-bottom: 15px; // Reduced margin
  border: 1px solid ${primaryColor};
  border-radius: 5px;
  background-color: #0e0e0e;
  color: ${primaryColor};
  font-size: 14px; // Slightly smaller font size

  &:focus {
    outline: none;
    border-color: #ffffff;
  }
`;

const TextArea = styled.textarea`
  padding: 8px; // Reduced padding for compactness
  margin-bottom: 15px; // Reduced margin
  border: 1px solid ${primaryColor};
  border-radius: 5px;
  background-color: #0e0e0e;
  color: ${primaryColor};
  font-size: 14px;
  resize: none;
  height: 120px; // Slightly smaller height for compactness

  &:focus {
    outline: none;
    border-color: #ffffff;
  }
`;

const SubmitButton = styled.button`
  padding: 10px; // Reduced padding
  background-color: ${primaryColor};
  color: #000;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #82c5cc;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.p`
  font-size: 14px;
  color: ${primaryColor};
  margin-top: 15px;
`;

const ErrorMessage = styled.p`
  font-size: 14px;
  color: #ff0000;
  margin-top: 15px;
`;

// Styled component for additional vertical space
const Spacer = styled.div`
  height: 100px; /* Adjust the height as needed */
`;


const SupportPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulate a form submission to a server
    setTimeout(() => {
      if (formData.email && formData.message) {
        setSubmitSuccess("Your message has been sent successfully.");
      } else {
        setSubmitSuccess("Failed to send message. Please try again.");
      }
      setIsSubmitting(false);
    }, 2000);
  };

  return (
    <>
    <SupportWrapper>
      <SupportHeader>Contact Support</SupportHeader>
      <SupportForm onSubmit={handleSubmit}>
        <Label htmlFor="name">Name</Label>
        <Input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />

        <Label htmlFor="email">Email</Label>
        <Input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />

        <Label htmlFor="subject">Subject</Label>
        <Input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleInputChange}
          required
        />

        <Label htmlFor="message">Message</Label>
        <TextArea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          required
        />

        <SubmitButton type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Sending..." : "Send Message"}
        </SubmitButton>
      </SupportForm>

      {submitSuccess && (
        submitSuccess.startsWith("Your")
          ? <SuccessMessage>{submitSuccess}</SuccessMessage>
          : <ErrorMessage>{submitSuccess}</ErrorMessage>
      )}
             <Footer/>

    </SupportWrapper>
       <Spacer/>
      </>
  );
};

export default SupportPage;
