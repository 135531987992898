// src/pages/CryptoOnrampPage.tsx

import React from 'react';
import CryptoOnramp from '../components/CryptoOnramp';  // Assuming CryptoOnramp is in the components folder

const CryptoOnrampPage: React.FC = () => {
  return (
    <div>
      <CryptoOnramp />
    </div>
  );
};

export default CryptoOnrampPage;
