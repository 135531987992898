// src/features/boxesSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { BoxType, Box } from "../utils/boxTypes";
import { AppDispatch, RootState } from "../app/store";
import axios from "axios";
import {  getHotBoxesLimit, handleLogoutUser } from "../utils/utility";
import { useNavigate } from "react-router-dom";
import { selectSettingsLoaded } from "./settingssSlice";

interface BoxesState {
  boxes: Box[];
  featuredBoxes: Box[];
  newBoxes: Box[];
  hotBoxes: Box[];
  limit: number; // Add a limit property to the state
}

const initialState: BoxesState = {
  boxes: [],
  featuredBoxes: [],
  newBoxes: [],
  hotBoxes: [],
  limit: 20, // Set an initial limit
};

// Thunk to fetch boxes from the API
export const fetchBoxes = createAsyncThunk<
  { boxType: BoxType; boxes: any[] }, // Return type (object with boxType and boxes array)
  BoxType,                            // Argument type (boxType)
  {
    dispatch: AppDispatch; // Dispatch type
    state: RootState;      // State type
    rejectValue: string;   // Optional reject value type (string in this case)
  }
>(
  "boxes/fetchBoxes",
  async (boxType, { getState, rejectWithValue, dispatch }) => {
    const maxRetries = 3; // Maximum number of retries
    const retryDelay = 1000; // Delay between retries in milliseconds

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      const state = getState();

      // Check if settings are loaded
      if (!selectSettingsLoaded(state)) {
        if (attempt === maxRetries - 1) {
          return rejectWithValue("Settings are not loaded yet after maximum retries");
        }

        // Wait before the next attempt
        await new Promise(resolve => setTimeout(resolve, retryDelay));
        continue; // Retry to check if settings are loaded
      }

      try {
        let limit = state.boxes.limit; // Get the limit from the state

        if (boxType === BoxType.Hot) {
          // Access settings from the state
          const { settings } = state.settings; 
          limit = getHotBoxesLimit(settings);
        }

        // Fetch the boxes data
        const response = await axios.get(
          `/boxes?offset=0&limit=${limit}&order=${boxType}&featured=${boxType === BoxType.Featured}`
        );

        // Return boxType and boxes list
        return { boxType, boxes: response.data.list } as const; // Ensure to return the correct type
      } catch (error: any) {
        // Check for 401 Unauthorized status
        if (error.response && error.response.status === 401) {
          handleLogoutUser(dispatch, useNavigate()); // Dispatch the logout action
          return rejectWithValue("Unauthorized access - logging out");
        }

        // Handle other errors and reject with the error message
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data.message);
        } else {
          // If this is the last attempt, reject with a failure message
          if (attempt === maxRetries - 1) {
            return rejectWithValue("Failed to fetch boxes after maximum retries");
          }

          // Wait before retrying on error
          await new Promise(resolve => setTimeout(resolve, retryDelay));
        }
      }
    }

    // If it reaches here without returning, reject with a generic error
    return rejectWithValue("An unexpected error occurred");
  }
);


const boxesSlice = createSlice({
  name: "boxes",
  initialState,
  reducers: {
    // ... other reducers
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload; // Update the limit in the state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBoxes.fulfilled, (state, action: PayloadAction<{ boxType: BoxType; boxes: Box[] }>) => {
      const { boxType, boxes } = action.payload;
      switch (boxType) {
        case BoxType.Featured:
          state.featuredBoxes = boxes;
          break;
        case BoxType.New:
          state.newBoxes = boxes;
          break;
        case BoxType.Hot:
          state.hotBoxes = boxes;
          break;
        default:
          state.boxes = boxes;
      }
    });
  },
});

export const { setLimit } = boxesSlice.actions;
export default boxesSlice.reducer;
