import styled from "styled-components";

interface BoxesPageHeadingProps {
  $gradientColors: string[]; // An array of color values for the linear gradient
}

const BoxesPageHeading = styled.div<BoxesPageHeadingProps>`
  flex-grow: 0;
  font-family: 'BernierShade', sans-serif; /* Use the custom font */
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 32px; /* Add 32px spacing from the top */

  background: linear-gradient(to bottom, ${(props) => props.$gradientColors.join(", ")});
  -webkit-background-clip: text; /* Apply the gradient to the text */
  background-clip: text;
  
  color: transparent; /* Make the text transparent to reveal the gradient background */

  @media (max-width: 768px) {
    font-size: 36px; /* Adjust font size for smaller screens */
  }
`;

export default BoxesPageHeading;
