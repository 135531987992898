// src/components/FeaturedBoxes.tsx
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import styled from "styled-components";
import { BoxType } from "../utils/boxTypes";
import { fetchBoxes } from "../features/boxesSlice";
import { fetchAllBoxes, setCurrentPage } from "../features/allBoxesSlice";
import { setBoxDetails } from "../features/boxDetailsSlice";
import { useNavigate } from "react-router-dom";
import { formatNumber, getImageSource } from "../utils/utility";
import fireImage from "../assets/fire.svg";
import starImage from "../assets/star.svg";
import newImage from "../assets/new_tag_1.svg";

export const SquareGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 16px;
  margin-top: 16px;
`;

export const SquareBox = styled.div`
  width: 100%;
  padding-top: 121.4%;
  position: relative;
  border-radius: 16px;
  font-family: SonsieOne;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-image: url("/assets/Box_Container_BG.png"); /* Add your image path here */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: visible; /* Ensure content doesn't affect the box size */
`;

export const BoxContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box;
`;

export const BoxTitle = styled.h3`
  font-family: Verdana;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  position: absolute;
  bottom: 3.7%;
  left: 7.1%;
  right: 7.1%;
  margin: 0; /* Remove default margin */
`;

export const PriceTag = styled.div`
  width: auto;
  height: 7.3%;
  margin: 20px 8px 5px 0;
  padding: 2px 10px 1px;
  background-color: #80d5ff;
  position: absolute;
  bottom: 11.8%;
  left: 7.1%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center; /* Vertical centering */
  font-family: Verdana;
  font-size: clamp(
    10px,
    3vw,
    14px
  ); /* Adjust the min, viewport width, and max values */
  /* font-size: 1vw; Adjust the multiplier as needed */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f00;
`;

export const BoxImage = styled.img<{ $isHovered: boolean }>`
  width: 71.4%;
  object-fit: cover;
  position: absolute;
  top: ${(props) => (props.$isHovered ? "8%" : "14%")};
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out; /* Add smooth transition effect */
`;

//const BoxTag = styled.img<{ top: string }>`

const BoxTag = styled(({ top, ...props }: { top: string } & React.HTMLProps<HTMLImageElement>) => (
  <img {...props} />
))<{ top: string }>`
  object-fit: cover;
  position: absolute;
  height: 18%;
  width: auto;
  top: ${(props) => props.top };
  left: 7%;
`;

const NoRecordsFoundMessage = styled.p`
  padding: 50px;
  text-align: center;
  color: #40a5c5;
`;


interface DynamicBoxesProps {
  boxCount: number;
  boxType: BoxType; // Use the enum instead of strings
}

const DynamicBoxes: React.FC<DynamicBoxesProps> = ({ boxCount, boxType }) => {
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch
  const boxes = useSelector((state: RootState) => {
    switch (boxType) {
      case BoxType.Featured:
        return state.boxes.featuredBoxes;
      case BoxType.New:
        return state.boxes.newBoxes;
      case BoxType.Hot:
        return state.boxes.hotBoxes;
      default:
        return state.boxes.boxes;
    }
  });

  useState()

  const [hoveredBox, setHoveredBox] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleBoxClick = (boxId: string, name: string) => {
    dispatch(setBoxDetails(boxId));
    // navigate(`/boxes/${name.replace(/\s/g, "-")}`);
    navigate(`/boxes/${boxId}`);

  };

  useEffect(() => {
    // Dispatch the fetchBoxes thunk when the component mounts
    dispatch(fetchBoxes(boxType));
  }, [dispatch, boxType]);

  function imageTag() {
    let imgSrc = "";
    let top = 0 ;
    if (boxType == BoxType.Featured) {
      imgSrc = starImage ;
      top = -4 ;

    } else if (boxType == BoxType.Hot) {
      imgSrc = fireImage ;
      top = -4 ;
    }
    else if (boxType == BoxType.New) {
      imgSrc = newImage ;
      top = -3.5 ;
    }
    return <BoxTag top={top+"%"} src={imgSrc} alt="fire" />;
  }

  return (
    <SquareGrid columns={boxCount}>
      {boxes.map((box, index) => (

        <SquareBox
          key={index}
          onMouseEnter={() => setHoveredBox(box.id)}
          onMouseLeave={() => setHoveredBox(null)}
          onClick={() => handleBoxClick(box.id, box.name)}
        >

          <BoxContent>

            <BoxImage
              src={getImageSource(box.picture)}
              $isHovered={hoveredBox === box.id}
            />
            <BoxTitle>{box.name}</BoxTitle>
            <PriceTag>${formatNumber(box.final_value)}</PriceTag>
          </BoxContent>
          {imageTag()}
        </SquareBox>
      ))}
    </SquareGrid>
  );
};

export default DynamicBoxes;

interface DynamicBoxesForAllProps {
  boxCount: number;
  boxType: BoxType; // Use the enum instead of strings
  page: string;
}

export const DynamicBoxesForAll: React.FC<DynamicBoxesForAllProps> = ({
  boxCount,
  boxType,
  page
}) => {
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch
  const [hoveredBox, setHoveredBox] = useState<string | null>(null);
  const navigate = useNavigate();

  const allBoxes =
    useSelector((state: RootState) => state.allBoxes.boxes) ?? []; // Ensure allBoxes is an array
  const isFetching = useSelector(
    (state: RootState) => state.allBoxes.isLoading
  );
  const currentPage = useSelector(
    (state: RootState) => state.allBoxes.currentPage
  );
  const totalItems = useSelector(
    (state: RootState) => state.allBoxes.totalItems
  );
  const boxesPerPage = useSelector(
    (state: RootState) => state.allBoxes.boxesPerPage
  );

  // Ref for the last box element
  const lastBoxRef = useRef<HTMLDivElement>(null);

  // Intersection Observer to trigger fetchMoreData when the last box is in view
  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting && !isFetching) {
        const pages = Math.ceil(totalItems / boxesPerPage);
        if (currentPage < pages) {
          dispatch(setCurrentPage(currentPage + 1)); // Action to fetch more data

          const isForGiftsCategory = page != "boxes";
          dispatch(fetchAllBoxes( {boxType:boxType, isForGiftCategory: isForGiftsCategory}));
        }
      }
    },
    { threshold: 0.1 }
  );

  useEffect(() => {
    // Dispatch the fetchBoxes thunk when the component mounts
    dispatch(setCurrentPage(1)); // Action to fetch more data
    const isForGiftsCategory = page != "boxes";
    dispatch(fetchAllBoxes( {boxType:boxType, isForGiftCategory: isForGiftsCategory}));
}, [dispatch, boxType]);

  useEffect(() => {
    if (lastBoxRef.current) {
      observer.observe(lastBoxRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (lastBoxRef.current) {
        observer.unobserve(lastBoxRef.current);
      }
    };
  }, [observer, isFetching]);

  const handleBoxClick = (boxId: string, name: string) => {
    dispatch(setBoxDetails(boxId));
    // navigate(`/${page}/${name.replace(/\s/g, "-")}`);
    navigate(`/${page}/${boxId}`);

  };

  return <> { totalItems == 0 ? (
    <NoRecordsFoundMessage>{isFetching ? "Loading ..." : "No records found"} </NoRecordsFoundMessage>
    ) :(
    <SquareGrid columns={boxCount}>
      {allBoxes.map((box, index) => {
        if (index === allBoxes.length - 1) {
          return (
            <div key={index} ref={lastBoxRef}>
              <SquareBox
                onMouseEnter={() => setHoveredBox(box.id)}
                onMouseLeave={() => setHoveredBox(null)}
                onClick={() => handleBoxClick(box.id, box.name)}
              >
                <BoxContent>
                  <BoxImage
                    src={getImageSource(box.picture)}
                    $isHovered={hoveredBox === box.id}
                  />
                  <BoxTitle>{box.name}</BoxTitle>
                  <PriceTag>${formatNumber(box.final_value)}</PriceTag>
                </BoxContent>
              </SquareBox>
            </div>
          );
        } else {
          return (
            <SquareBox
              key={index}
              onMouseEnter={() => setHoveredBox(box.id)}
              onMouseLeave={() => setHoveredBox(null)}
              onClick={() => handleBoxClick(box.id, box.name)}
            >
              <BoxContent>
                <BoxImage
                  src={getImageSource(box.picture)}
                  $isHovered={hoveredBox === box.id}
                />
                <BoxTitle>{box.name}</BoxTitle>
                <PriceTag>${formatNumber(box.final_value)}</PriceTag>
              </BoxContent>
            </SquareBox>
          );
        }
      })}
    </SquareGrid>
  )}
  </>;
};
