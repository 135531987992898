// Footer.tsx
import styled from "styled-components";
import localization from "../services/localization";

const FooterWrapper = styled.div`
  background-color: #000c0d;
  color: #fff;
  padding: 10px;
  text-align: center;
  height: auto;
  /* border: 2px solid red;
  border-color: #fff; */
  margin-top: 32px;
`;

// Styled component for the list
const StyledList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  @media (min-width: 768px) {
    gap: 20px;
  }
`;

// Styled component for the list items
const StyledListItem = styled.li`
  a {
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #cccccc;

    &:hover {
      color: #ffffff;
    }
  }
`;

const SocialList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  @media (min-width: 768px) {
    gap: 20px;
  }
`;

const SocialListItem = styled.li`
  display: flex;
  gap: 10px;


  a {
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      border-radius: 50%;
      transition: filter 0.3s;

      &:hover {
        filter: brightness(1.2);
      }
    }
  }
`;


const PaymentList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 0;
  mix-blend-mode: luminosity;

  @media (min-width: 768px) {
    gap: 96px;
  }

`;

const PaymentMethodLogo = styled.img`
  width: 95.2px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
`;

const WeChatPayLogo = styled.img`
  width: 81.4px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
`;

const PayPalLogo = styled.img`
  width: 90.5px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
`;


const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <SocialList>
        <SocialListItem>
          <a href="/" target="_blank">
          <img
              src="/assets/twitter.png"
              srcSet="/assets/twitter@2x.png 2x, /assets/twitter@3x.png 3x"
            />
          </a>
        </SocialListItem>
        <SocialListItem>
          <a href="/" target="_blank">
          <img
              src="/assets/youtube.png"
              srcSet="/assets/youtube@2x.png 2x, /assets/youtube@3x.png 3x"
            />
          </a>
        </SocialListItem>
        <SocialListItem>
          <a href="/" target="_blank">
          <img
              src="/assets/facebook.png"
              srcSet="/assets/facebook@2x.png 2x, /assets/facebook@3x.png 3x"
            />
          </a>
        </SocialListItem>
        <SocialListItem>
          <a href="/" target="_blank">
            <img
              src="/assets/instagram.png"
              srcSet="/assets/instagram@2x.png 2x, /assets/instagram@3x.png 3x"
            />
          </a>
        </SocialListItem>
        <SocialListItem>
          <a href="/" target="_blank">
            <img
              src="/assets/tik-tok.png"
              srcSet="/assets/tik-tok@2x.png 2x, /assets/tik-tok@3x.png 3x"
            />
          </a>
        </SocialListItem>
      </SocialList>

      <StyledList>
        {/* <StyledListItem>
          <a href="/">{localization.contacts}</a>
        </StyledListItem> */}
        <StyledListItem>
          <a href="/how-it-works">{localization.how_it_works}</a>
        </StyledListItem>
        <StyledListItem>
          <a href="/terms-of-service">{localization.term_of_service}</a>
        </StyledListItem>
        <StyledListItem>
          <a href="/privacy-policy">{localization.privacy_statement}</a>
        </StyledListItem>
        {/* <StyledListItem>
          <a href="/">{localization.provably_fair}</a>
        </StyledListItem> */}
        <StyledListItem>
          <a href="/support">{localization.support}</a>
        </StyledListItem>
        {/* <StyledListItem>
          <a href="/">{localization.partnership}</a>
        </StyledListItem> */}
        <StyledListItem>
          <a href="/cookie-policy">{localization.cookies_policy}</a>
        </StyledListItem>
        {/* <StyledListItem>
          <a href="/">{localization.aml_policy}</a>
        </StyledListItem> */}
      </StyledList>

      {/* <PaymentList>
      <a href="/" target="_blank">
        <PaymentMethodLogo
          src="/assets/alipay.png"
          srcSet="/assets/alipay@2x.png 2x, /assets/alipay@3x.png 3x"
          alt="Alipay Logo"
        />
      </a>
      <a href="/" target="_blank">
        <WeChatPayLogo
          src="/assets/we-chat-pay.png"
          srcSet="/assets/we-chat-pay@2x.png 2x, /assets/we-chat-pay@3x.png 3x"
          alt="WeChat Pay Logo"
        />
      </a>
      <a href="/" target="_blank">
        <PayPalLogo
          src="/assets/paypal.png"
          srcSet="/assets/paypal@2x.png 2x, /assets/paypal@3x.png 3x"
          alt="PayPal Logo"
        />
      </a>
    </PaymentList> */}

    </FooterWrapper>
  );
};

export default Footer;
