import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash, FaCheck, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../app/store';
import { signup } from '../features/userSlice';
import ReCAPTCHA from 'react-google-recaptcha';
import { ModalType, useModal } from '../context/ModalContext';

const SignUpWrapper = styled.div`
  background-color: #193E40;
  padding: 30px;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: white;
  cursor: pointer;

  &:hover {
    color: #bbb;
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: white;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Button = styled.button`
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: #23877E;
  }
`;

const SignUpButton = styled(Button)`
  background-color: #2AA298;

  &:hover {
    background-color: #23877E;
  }
`;

const SignInButton = styled(Button)`
  background-color: #ffffff;
  color: #193E40;
  border: 2px solid #2AA298;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const LoadingIndicator = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2AA298;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const CaptchaWrapper = styled.div`
  margin-bottom: 15px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  padding: 10px;
  padding-right: 40px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
`;

const TogglePasswordButton = styled.div`
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  color: #2AA298;
  font-size: 18px;

  &:focus {
    outline: none;
  }
`;

const ValidationList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between items */
`;

const ValidationItem = styled.li<{ isValid: boolean }>`
  color: ${props => props.isValid ? 'green' : 'red'};
  display: flex;
  align-items: center;
  flex: 1 1 auto; /* Allow items to grow based on content */
  min-width: 80px; /* Ensure that items don't get too small */
  max-width: 150px; /* Prevent them from getting too large */
  white-space: nowrap; /* Prevent the text from wrapping */
  font-size: 12px; /* Adjust the font size as needed */
`;


type SignUpProps = {};

export const SignUp: React.FC<SignUpProps> = ({  }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const signupSuccess = useSelector((state: RootState) => state.user.signupSuccess);
  const signupError = useSelector((state: RootState) => state.user.signupError);
  const signupLoading = useSelector((state: RootState) => state.user.signupLoading);
  const {closeModal, openModal } = useModal();

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setIsChecked(false);
    setCaptchaToken(null);
    setShowPassword(false);
  };

  const validatePassword = (password: string) => {
    return {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
  };

  const validationResults = validatePassword(password);

  const handleSignUp = async () => {
    if (!firstName || !lastName || !email || !password) {
      toast.error("All fields are required!");
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!validationResults.length || !validationResults.uppercase || !validationResults.lowercase || !validationResults.number || !validationResults.specialChar) {
      toast.error("Password must meet all criteria.");
      return;
    }

    if (!isChecked) {
      toast.error("You must agree to the terms and conditions.");
      return;
    }

    if (!captchaToken) {
      toast.error("Please complete the CAPTCHA.");
      return;
    }

    try {
      await dispatch(signup({ email, password, firstName, lastName })).unwrap();
      if (signupSuccess) {
        toast.success("Sign-up successful!");
      }
    } catch (error) {
      if (signupError) {
        toast.error(signupError);
      }
    }
  };

  useEffect(() => {
    if (signupSuccess) {
      resetForm();
      closeModal(); // Close modal when sign-in is successful
    }
  }, [signupSuccess, dispatch, closeModal]);

  return (
    <SignUpWrapper>
      <CloseButton onClick={() => { resetForm(); closeModal(); }}>X</CloseButton>
      <Title>Sign Up</Title>
      <Form>
        <Input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <InputWrapper>
          <Input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TogglePasswordButton onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </TogglePasswordButton>
        </InputWrapper>

        <ValidationList>
          <ValidationItem isValid={validationResults.length}>
            {validationResults.length ? <FaCheck /> : <FaTimes />} 8 Characters
          </ValidationItem>
          <ValidationItem isValid={validationResults.uppercase}>
            {validationResults.uppercase ? <FaCheck /> : <FaTimes />} Uppercase
          </ValidationItem>
          <ValidationItem isValid={validationResults.lowercase}>
            {validationResults.lowercase ? <FaCheck /> : <FaTimes />} Lowercase
          </ValidationItem>
          <ValidationItem isValid={validationResults.number}>
            {validationResults.number ? <FaCheck /> : <FaTimes />} Number
          </ValidationItem>
          <ValidationItem isValid={validationResults.specialChar}>
            {validationResults.specialChar ? <FaCheck /> : <FaTimes />} Special Character
          </ValidationItem>
        </ValidationList>

        <CheckboxWrapper>
          <Checkbox
            type="checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <label style={{ color: 'white' }}>
            I agree with the terms and conditions of the user agreement and confirm that I am at least 18 years old.
          </label>
        </CheckboxWrapper>

        <CaptchaWrapper>
          <ReCAPTCHA
            sitekey="6LeIpiUqAAAAAKK5YvPiPfgwUHy5JKDPnVZ64sLV"
            onChange={(token) => setCaptchaToken(token)}
          />
        </CaptchaWrapper>

        <SignUpButton
          type="button"
          onClick={handleSignUp}
          disabled={signupLoading || !captchaToken}
        >
          {signupLoading ? <LoadingIndicator /> : 'Sign Up'}
        </SignUpButton>

        <SignInButton type="button" onClick={() => {
          openModal(ModalType.SIGN_IN);
        }}>
          Sign In
        </SignInButton>
      </Form>
    </SignUpWrapper>
  );
};


function validateEmail(email: string): boolean {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
}
