import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../app/store";
import axios from "axios";
import { Box, Item } from "../utils/boxTypes";
import { useNavigate } from "react-router-dom";
import { handleLogoutUser } from "../utils/utility";

// Define the type for queryParams
interface QueryParams {
  offset: number;
  limit: number;
}

interface ChaosState {
  chaosBoxesList: Box[];
  chaosBoxItemsList: Item[];
  isLoadingChaosBoxes: boolean;
  isLoadingChaosItems: boolean;
  likeDislikeMarked: boolean;
  isLoadingFavouriteBoxes: boolean;
  favouriteBoxesList: Box[];
  isNeedToRefresh: boolean;
  currentPage: number;
  boxesPerPage: number;
  totalItems: number;
}

const initialState: ChaosState = {
  chaosBoxesList: [],
  chaosBoxItemsList: [],
  isLoadingChaosBoxes: false,
  isLoadingChaosItems: false,
  likeDislikeMarked: false,
  isLoadingFavouriteBoxes: false,
  favouriteBoxesList: [],
  isNeedToRefresh: false,
  currentPage: 1,
  boxesPerPage: 20,
  totalItems: 0,
};

interface IPostBoxLikeDislike {
  boxId: string;
  isLiked: boolean;
}

const chaosSlice = createSlice({
  name: "chaosBoxes",
  initialState,
  reducers: {
    setBoxesPerPage: (state, action: PayloadAction<number>) => {
      state.boxesPerPage = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChaosBoxes.pending, (state) => {
        state.isLoadingChaosBoxes = true;
      })
      .addCase(
        fetchChaosBoxes.fulfilled,
        (state, action: PayloadAction<{ boxes: Box[] }>) => {
          const { boxes } = action.payload;
          state.chaosBoxesList = boxes;
          state.isLoadingChaosBoxes = false;
        }
      );

    builder.addCase(fetchChaosBoxes.rejected, (state, action) => {
      state.isLoadingChaosBoxes = false;
    });

    builder
      .addCase(fetchChaosBoxItems.pending, (state) => {
        state.isLoadingChaosItems = true;
      })
      .addCase(
        fetchChaosBoxItems.fulfilled,
        (state, action: PayloadAction<{ items: Item[] }>) => {
          const { items } = action.payload;
          state.chaosBoxItemsList = items;
          state.isLoadingChaosItems = false;
        }
      );

    builder.addCase(postBoxLikeDislike.fulfilled, (state, action) => {
      const { res, boxId } = action.payload || {
        res: false,
        boxId: "",
      };

      if (res) {
        // Remove the box from chaosBoxesList based on boxId
        const updatedBoxesList = state.chaosBoxesList.filter(
          (box) => box.id !== boxId
        );

        state.chaosBoxesList = updatedBoxesList;
      }
      state.likeDislikeMarked = res;
      console.log(res);
      state.isLoadingChaosItems = false;
      state.isNeedToRefresh = true;
    });

    builder.addCase(postBoxLikeDislike.rejected, (state, action) => {
      state.isLoadingChaosItems = false;
    });

    builder.addCase(fetchFavouriteBoxes.fulfilled, (state, action) => {
      const { boxes, total } = action.payload || {
        boxes: [],
        total: 0,
      };

      if (state.currentPage == 1) {
        state.favouriteBoxesList = boxes;
      } else if (boxes.length > 0) {
        state.favouriteBoxesList = [...state.favouriteBoxesList, ...boxes];
      }
      state.isLoadingFavouriteBoxes = false;
      state.totalItems = total;
      state.isNeedToRefresh = false;
    });

    builder.addCase(fetchFavouriteBoxes.pending, (state) => {
      state.isLoadingFavouriteBoxes = true;
    });

    builder.addCase(fetchFavouriteBoxes.rejected, (state, action) => {
      state.isLoadingFavouriteBoxes = false;
    });
  },
});

// Thunk to fetch chaos boxes from the API
export const fetchChaosBoxes = createAsyncThunk<
  { boxes: any[] }, // Return type (object with boxes array)
  void, // Argument type (no arguments in this case)
  {
    dispatch: AppDispatch; // Dispatch type
    state: RootState; // State type
    rejectValue: string; // Optional reject value type (string in this case)
  }
>(
  "chaosBoxes/fetchChaosBoxes",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      // Fetch chaos boxes data
      const response = await axios.get(`/boxes/chaos?offset=0&limit=1000`);

      // Return boxes list
      return { boxes: response.data.list };
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Dispatch the logout action
        return rejectWithValue("Unauthorized access - logging out");
      }

      // Handle other errors and reject with the error message
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("Failed to fetch chaos boxes");
      }
    }
  }
);


// Thunk to fetch chaos boxes from the API
export const resetLikes = createAsyncThunk<
  void, // Return type (object with boxes array)
  void, // Argument type (no arguments in this case)
  {
    dispatch: AppDispatch; // Dispatch type
    state: RootState; // State type
    rejectValue: string; // Optional reject value type (string in this case)
  }
>(
  "chaosBoxes/resetLikes",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState() as RootState;

      const userId = state.user.user?.id

      // Fetch chaos boxes data
      const response = await axios.post(`https://demo1-api.boxchaos.com/aapi/user/${userId}/reset_likes`);

    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Dispatch the logout action
        return rejectWithValue("Unauthorized access - logging out");
      }

      // Handle other errors and reject with the error message
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("Failed to fetch chaos boxes");
      }
    }
  }
);


// Thunk to fetch chaos box items from the API
export const fetchChaosBoxItems = createAsyncThunk<
  { items: any[] }, // Return type (object with items array)
  string, // Argument type (boxId)
  {
    dispatch: AppDispatch; // Dispatch type
    state: RootState; // State type
    rejectValue: string; // Optional reject value type (string in this case)
  }
>(
  "chaosBoxes/fetchChaosBoxItems",
  async (boxId, { getState, rejectWithValue, dispatch }) => {
    try {
      // Fetch chaos box items data
      const response = await axios.get(`box?id=${boxId}`);

      // Return items list
      return { items: response.data.items };
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Dispatch the logout action
        return rejectWithValue("Unauthorized access - logging out");
      }

      // Handle other errors and reject with the error message
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("Failed to fetch chaos box items");
      }
    }
  }
);

// Define the interface for the payload
interface IPostBoxLikeDislike {
  boxId: string;
  isLiked: boolean;
}

// Thunk to post like/dislike for a box
export const postBoxLikeDislike = createAsyncThunk<
  { res: boolean; boxId: string }, // Return type (object with result and boxId)
  IPostBoxLikeDislike, // Argument type (object containing boxId and isLiked)
  {
    dispatch: AppDispatch; // Dispatch type
    state: RootState; // State type
    rejectValue: string; // Optional reject value type (string in this case)
  }
>(
  "chaosBoxes/postBoxLikeDislike",
  async ({ boxId, isLiked }, { rejectWithValue, dispatch }) => {
    try {
      // Post like/dislike for the box
      const response = await axios.post(
        `box/like?id=${boxId}&liked=${isLiked}`
      );

      // Check if the response status is 200
      if (response.status === 200) {
        return { res: true, boxId }; // Return success
      } else {
        return { res: false, boxId }; // Return failure
      }
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Dispatch the logout action
        return rejectWithValue("Unauthorized access - logging out");
      }

      // Handle other errors and reject with the error message
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("Failed to like/dislike the box");
      }
    }
  }
);

// Thunk to fetch favorite boxes
export const fetchFavouriteBoxes = createAsyncThunk<
  { boxes: any[]; total: number }, // Return type (object with boxes and total)
  void, // Argument type (no arguments)
  {
    dispatch: AppDispatch; // Dispatch type
    state: RootState; // State type
    rejectValue: string; // Optional reject value type (string in this case)
  }
>(
  "chaosBoxes/fetchFavouriteBoxes",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState() as RootState;

      if (!state.chaosBoxes) {
        console.error("chaosBoxes state is not defined");
        return rejectWithValue("ChaosBoxes state is not defined");
      }

      const { currentPage, boxesPerPage } = state.chaosBoxes; // Ensure to use the correct slice state
      const offset = (currentPage - 1) * boxesPerPage;

      // Use the defined type for query parameters
      const queryParams: QueryParams = {
        offset,
        limit: boxesPerPage,
      };

      const response = await axios.get("boxes/fav", {
        params: queryParams,
      });

      console.log("favourite boxes length: " + response.data.list.length);

      return { boxes: response.data.list, total: response.data.total };
    } catch (error: any) {
      // Check for 401 Unauthorized status
      if (error.response && error.response.status === 401) {
        handleLogoutUser(dispatch, useNavigate()); // Dispatch the logout action
        return rejectWithValue("Unauthorized access - logging out");
      }

      // Handle other errors and reject with the error message
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("Failed to fetch favorite boxes");
      }
    }
  }
);

export const { setBoxesPerPage, setCurrentPage } = chaosSlice.actions;
export default chaosSlice.reducer;

export const chaosBoxesList = (state: RootState) =>
  state.chaosBoxes.chaosBoxesList;
export const chaosBoxItemsList = (state: RootState) =>
  state.chaosBoxes.chaosBoxItemsList;
export const favouriteBoxesList = (state: RootState) =>
  state.chaosBoxes.favouriteBoxesList;
export const isLoadingChaosBoxes = (state: RootState) =>
  state.chaosBoxes.isLoadingChaosBoxes;
export const isLoadingChaosItems = (state: RootState) =>
  state.chaosBoxes.isLoadingChaosItems;
export const isLoadingFavouriteBoxes = (state: RootState) =>
  state.chaosBoxes.isLoadingFavouriteBoxes;
export const likeDislikeMarked = (state: RootState) =>
  state.chaosBoxes.likeDislikeMarked;
export const isNeedToRefreshFavorites = (state: RootState) =>
  state.chaosBoxes.isNeedToRefresh;
