// src/localization.ts

import LocalizedStrings, { GlobalStrings } from 'react-localization';
import { UUIDStorage } from './storage';
import { Subject } from 'rxjs';

const translations = {
  en: {
    featured_boxes: "Featured Boxes",
    hot_boxes: "Hot Boxes",
    new_boxes: "New Boxes",
    gifts:"Gifts",
    signup: "Signup",
    login: "Login",
    all_boxes:"All Boxes",
    chaos:"Chaos",
    affiliates:"Affiliates",
    vips:"VIPs",
    sortby:"Sort By:",
    name:"Name",
    featured:"Featured",
    old:"Old",
    new:"New",
    hot:"Hot",
    search_boxes_placeholder:"Search Boxes ...",
    open_box: "OPEN BOX",
    open_vip_box: "OPEN VIP BOX",
    live_feed: "LIVE FEED",
    profile_settings: "Profile Settings",
    logout: "Logout",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    contacts: "Contacts",
    how_it_works:"How it works",
    faq:"FAQ",
    term_of_service:"Term of service",
    privacy_statement:"Privacy policy",
    provably_fair:"Provably fair",
    support:"Support",
    partnership:"Partnership",
    cookies_policy:"Cookies policy",
    aml_policy:"aml policy",
    chaos_content_coming_soon:"Chaos Content, coming soon",
    gift_content_coming_soon: "Gifts Content, coming soon",
    affiliates_content_coming_soon: "Affiliates Content, coming soon",
    vips_content_coming_soon: "VIPS Content, coming soon",
    item_details: "ITEM DETAILS",
    send:"SEND",
    email_input_placeholder:"example@email.com",
    loading:"Loading...",
    items_in_the_box:"Items inside the box.",
    all_boxes_already_liked_disliked:"All boxes have already been liked or disliked.",
    favorite_boxes:"Favorite Boxes",
    bonus_boxes:"Bonus Boxes",
    like:"like",
    dislike:"dislike",
    no_image:"no image",
    back:"Back",
    error:"Error:",
    no_box_detail_available:"Box details are not available.",
    box_image:"Box Image",
    image:"image",
    no_box_selected:"No box has been selected.",
    no_favorite_boxes:"No favorite boxes.",
    heart:"heart",
    profile:"profile",
    logo:"Logo"
  },
  cn: {
    featured_boxes: "特色盒子",
    hot_boxes: "热门盒子",
    new_boxes: "新盒子",
    gifts:"礼物",
    signup: "注册",
    login: "登录",
    all_boxes:"所有盒子",
    chaos:"开一个",
    affiliates:"联盟营销",
    vips:"VIP客户专区",
    sortby:"排序方式",
    name:"名字",
    featured:"特色",
    old:"最早的",
    new:"近期的",
    hot:"热门的",
    search_boxes_placeholder:"搜索盒子",
    open_box: "打开盒子",
    open_vip_box: "OPEN VIP BOX",
    live_feed: "实时开盒",
    profile_settings: "个人资料设置",
    logout: "登出",
    one: "一",
    two: "二",
    three: "三",
    four: "四",
    five: "五",
    contacts: "联系方式",
    how_it_works:"开盒怎么玩？",
    faq:"常见问题",
    term_of_service:"服务条款",
    privacy_statement:"隐私申明",
    provably_fair:"公平证明",
    support:"客服支持",
    partnership:"合作伙伴",
    cookies_policy:"Cookie政策",
    aml_policy:"反洗钱政策",
    chaos_content_coming_soon:"“开一个”内容，即将上线",
    gift_content_coming_soon: "“礼物”内容，即将上线",
    affiliates_content_coming_soon: "“联盟营销”内容，即将上线",
    vips_content_coming_soon: "“VIP客户专区”内容，即将上线",
    item_details: "商品详情",
    send:"发送",
    email_input_placeholder:"example@email.com",
    loading:"加载中…",
    items_in_the_box:"商品在盒子内",
    all_boxes_already_liked_disliked:"所有已经喜欢或者不喜欢的盒子",
    favorite_boxes:"最喜欢的盒子",
    bonus_boxes:"Bonus Boxes",
    like:"喜欢",
    dislike:"不喜欢",
    no_image:"没有图片",
    back:"背后",
    error:"出错：",
    no_box_detail_available:"盒子详情不可用",
    box_image:"盒子图片",
    image:"图片",
    no_box_selected:"没有盒子被挑选",
    no_favorite_boxes:"没有最喜欢的盒子",
    heart:"喜欢",
    profile:"资料",
    logo:"商标"
  },
  ur: {
    featured_boxes: "نمایاں باکسز",
    hot_boxes:  "پرکشش بکس",
    new_boxes:  "نئے بکس",
    gifts:"تحائف",
    signup: "نیا اکاؤنٹ بنائیں",
    login: "لاگ ان کریں",
    all_boxes:"تمام بکسز",
    chaos:"افراتفری",
    affiliates:"ملحقہ",
    vips:"وی آئی پیز",
    sortby:"ترتیب",
    name:"نام",
    featured:"نمایاں",
    old:"پرانے",
    new:"نئے",
    hot:"پرکشش",
    search_boxes_placeholder:"یہاں بکسز کی تلاش کریں۔۔۔",
    open_box: "بکس کھولیں",
    open_vip_box: "OPEN VIP BOX",
    live_feed: "براھراست",
    profile_settings: "پروفائل کی ترتیبات",
    logout: "لاگ آؤٹ کریں",
    one: "۱",
    two: "۲",
    three: "۳",
    four: "۴",
    five: "۵",
    contacts: "رابطے",
    how_it_works:"یہ کیسے کام کرتا ہے",
    faq:"عمومی سوالات",
    term_of_service:"خدمت گزاری کی شرائط",
    privacy_statement:"رازداری کا بیان",
    provably_fair:"غالباً منصفانہ",
    support:"تعاون",
    partnership:"شراکت داری",
    cookies_policy:"کوکیز کی پالیسی",
    aml_policy:"ایمل پالیسی",
    chaos_content_coming_soon:"افراتفری کا مواد، جلد آرہا ہے۔",
    gift_content_coming_soon: "تحائف کا مواد، جلد آرہا ہے۔",
    affiliates_content_coming_soon: "ملحقہ مواد، جلد آرہا ہے۔",
    vips_content_coming_soon: "وی آئی پیز مواد، جلد آرہا ہے۔",
    item_details: "آئٹم کی تفصیلات",
    send:"بھیجیں",
    email_input_placeholder:"example@email.com",
    loading:"لوڈ ہو رہا ہے...",
    items_in_the_box:"باکس کے اندر اشیاء۔",
    all_boxes_already_liked_disliked:"تمام بکس کو پہلے ہی پسند یا ناپسند کیا جا چکا ہے۔",
    favorite_boxes:"پسندیدہ بکس",
    bonus_boxes:"Bonus Boxes",
    like:"پسند",
    dislike:"ناپسندیدگی",
    no_image:"تصویرنہیں",
    back:"پیچھے",
    error:"خرابی:",
    no_box_detail_available:"باکس کی تفصیلات دستیاب نہیں ہیں۔",
    box_image:"باکس کی تصویر",
    image:"تصویر",
    no_box_selected:"کوئی باکس منتخب نہیں کیا گیا ہے۔",
    no_favorite_boxes:"کوئی پسندیدہ باکس نہیں ہے۔",
    heart:"دل",
    profile:"پروفائل",
    logo:"لوگو"
  }
};

const localization = new LocalizedStrings(translations);

export default localization;

export const getLanguageSubject = new Subject<String>();

export function checkSelectedLanguage():string{
  let language = UUIDStorage.getInstance().getLanguage();
  localization.setLanguage(language);
  return language;
}


export function setLocalizationLanguage(language:string){
  const uuidStorage = UUIDStorage.getInstance();
  uuidStorage.setLanguage(language);
  getLanguageSubject.next(language);
}