import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import mammoth from 'mammoth';
import Footer from './Footer'; // Ensure Footer component is available

// Styled components
const DocumentContainer = styled.section`
  padding: 1rem;
  background-color: #1A1A1A;
  border-radius: 1.5rem;
  color: white;
  transition: background-color 0.3s, color 0.3s;

  @media (min-width: 768px) {
    padding: 1.25rem;
  }

  @media (min-width: 1024px) {
    padding: 2.5rem;
  }
`;

const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 2.5rem;
  line-height: 54px;
  font-weight: 500;
  text-align: center;
  color: white;
  transition: color 0.3s;

  @media (min-width: 1024px) {
    margin-bottom: 2.5rem;
  }
`;

const InfoPage = styled.div`
  font-size: 1rem;
  color: white;
  transition: color 0.3s;

  h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  p {
    line-height: 1.75rem;
  }

  ul {
    margin: 1rem 0;
    padding-left: 1.5rem;

    li {
      margin-bottom: 0.5rem;
    }
  }
`;

const Spacer = styled.div`
  height: 100px;
`;

// Generic DocumentLoader Component
interface DocumentLoaderProps {
  title: string;
  docxPath: string;
}

const DocumentLoader: React.FC<DocumentLoaderProps> = ({ title, docxPath }) => {
  const [docContent, setDocContent] = useState<string>('');

  useEffect(() => {
    const loadDocxFile = async () => {
      try {
        const response = await fetch(docxPath);
        const arrayBuffer = await response.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setDocContent(result.value); // This will be HTML content
      } catch (error) {
        console.error('Error loading the .docx file', error);
      }
    };

    loadDocxFile();
  }, [docxPath]);

  return (
    <>
      <DocumentContainer>
        <Title>{title}</Title>
        <InfoPage>
          <div dangerouslySetInnerHTML={{ __html: docContent }} />
        </InfoPage>
      </DocumentContainer>
      <Footer />
      <Spacer />
    </>
  );
};

export default DocumentLoader;
