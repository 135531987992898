// src/components/DynamicItems.tsx
import React, { useState } from "react";
import styled from "styled-components";
import { Item } from "../utils/boxTypes";
import noImageAvailable from "../../src/assets/no_image.png";
import { useItemDetails } from "../context/ItemDetailsContext";
import { formatNumber, getImagePath } from "../utils/utility";

const ItemsGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 16px;
  margin-top: 16px;
`;

const ItemContainer = styled.div`
  width: 100%;
  padding-top: 157.14%;
  position: relative;
  border-radius: 16px;
  font-family: SonsieOne;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-image: url("/assets/Item_Container_BG.png"); /* Add your image path here */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden; /* Ensure content doesn't affect the box size */
  border-radius: 16px;
  background-image: linear-gradient(160deg, #407a80 6%, rgba(0, 170, 255, 0.2) 100%), linear-gradient(to bottom, #407a80 0%, #407a80 100%);

`;

const ItemContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box;
`;

const ItemTitle = styled.h3`
  font-family: Verdana;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  position: absolute;
  /* bottom: auto; */
  top: 80.5%; /* Reset top property */
  left: 7.1%;
  right: 7.1%;
  margin: 0; /* Remove default margin */
`;

const ItemPrice = styled.div`
  width: auto;
  height: 7.3%;
  margin: 20px 8px 5px 0;
  padding: 2px 10px 1px;
  background-color: #80d5ff;
  position: absolute;
  bottom: 22%;
  left: 7.1%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center; /* Vertical centering */
  font-family: Verdana;
  font-size: clamp(
    10px,
    3vw,
    14px
  ); /* Adjust the min, viewport width, and max values */
  /* font-size: 1vw; Adjust the multiplier as needed */
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fd3;
`;

const WinProbabilityBadge = styled.span`
  width: auto;
  height: auto;
  font-family: Verdana;
  font-size: clamp(
    8px,
    3vw,
    10px
  );   font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 234, 128, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
  background-color: #002233;
  border-radius: 13px;
  padding: 4px;
  border: 1px solid #002233;
`;

const ItemImage = styled.img<{ $isHovered: boolean }>`
  width: calc(100% - 8px); /* Adjust width to account for margins */
  height: 64%;
  border-radius: 13px;
  /* background-color: #023; */
  object-fit: contain;
  position: absolute;
  top: ${(props) => (props.$isHovered ? "-4px" : "0")}; /* Move 4px up on hover */
  /* top: ${(props) => (props.$isHovered ? "8%" : "0px")}; */
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out; /* Add smooth transition effect */
  margin: 4px auto; /* Set auto for equal left and right margins */
`;

interface DynamicItemsProps {
  itemsCount: number;
  box: { items: Item[] };
}

// Function to generate the image source URL
const getImageSource = (pic: string | undefined): string => {
  if (pic && /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(pic)) {
    return getImagePath(pic);
  }
  return noImageAvailable;
};

const DynamicItems: React.FC<DynamicItemsProps> = ({ itemsCount, box }) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const { openDetails, closeDetails } = useItemDetails(); // Assuming you have access to the openDetails function from your context

  const handleSetProduct = (item: Item) => {
    openDetails(item);
  };

  return (
    <ItemsGrid columns={itemsCount}>
      {box.items.map((item, index) => (
        <ItemContainer
          key={index}
          onMouseEnter={() => setHoveredItem(item.item_id)}
          onMouseLeave={() => setHoveredItem(null)}
          onClick={() => handleSetProduct(item)}
        >
          <ItemContent>
            <ItemImage
              src={getImageSource(item.detail?.picture)}
              $isHovered={hoveredItem === item.item_id}
            >

              </ItemImage>
              <WinProbabilityBadge>{formatNumber(item.win_probability*100)}%</WinProbabilityBadge>

            <ItemTitle>{item.detail?.name}</ItemTitle>
            <ItemPrice>${formatNumber(item.detail?.price)}</ItemPrice>
          </ItemContent>
        </ItemContainer>
      ))}
    </ItemsGrid>
  );
};

export default DynamicItems;
