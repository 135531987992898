import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import {
  Box,
  LevelBox,
  LevelBoxWithStatus,
  VIPStatus,
} from "../utils/boxTypes";
import { AppDispatch } from "../app/store";

// Define the initial state
interface VIPState {
  vipStatus: VIPStatus | null;
  fetchVIPStatus: "idle" | "loading" | "succeeded" | "failed";
  fetchVIPError: string | null;
  levelBoxes: LevelBoxWithStatus[];
  getVipBoxesStatus: "idle" | "loading" | "succeeded" | "failed";
  getVipBoxesError: string | null;
  selectedLevelBoxWithStatus: LevelBoxWithStatus | null;
}

const initialState: VIPState = {
  vipStatus: null,
  fetchVIPStatus: "idle",
  fetchVIPError: null,
  levelBoxes: [],
  getVipBoxesStatus: "idle",
  getVipBoxesError: null,
  selectedLevelBoxWithStatus: null,
};

// Async action to fetch VIP status
export const fetchVIPStatus = createAsyncThunk<VIPStatus>(
  "vip/fetchVIPStatus",
  async () => {
    const response = await axios.get("me/vip_status");
    return response.data;
  }
);

// Modify getVipBoxes to calculate box statuses
export const getVipBoxes = createAsyncThunk<
  LevelBoxWithStatus[],
  void,
  { dispatch: AppDispatch; rejectValue: string }
>("/boxes/vipBoxes", async (_, { dispatch, rejectWithValue, getState }) => {
  try {
    // Fetch VIP status if not already fetched
    let vipStatus: VIPStatus | null = null;//(getState() as any).vip.vipStatus;
    if (!vipStatus) {
      const vipStatusResponse = await dispatch(fetchVIPStatus()).unwrap();
      vipStatus = vipStatusResponse as VIPStatus;
    }

    const userLevel = vipStatus?.level || 0;

    //TODO: here we need to 
    const openedBoxes = new Set(vipStatus?.boxes.map((box) => box.box_level));

    const response = await axios.get<Box[]>("vip_boxes", {
      headers: {
        accept: "application/json",
      },
    });

    const vipBoxes = response.data.filter((box) => box.is_vip);

    const levelNames = [
      "Basic",
      "Essence",
      "Ascent",
      "Glow",
      "Sparkle",
      "Gleam",
      "Aura",
      "Elegance",
      "Prestige",
      "Sovereign",
      "Supreme Luxe",
    ];

    const levels: LevelBoxWithStatus[] = Array.from(
      { length: 11 },
      (_, level) => {
        const boxForLevel = vipBoxes.find((box) => box.vip_level === level);

        // Determine the status of the level box
        let status: "locked" | "available" | "opened" = "locked";
        if (level <= userLevel) {
          status = openedBoxes.has(level) ? "opened" : "available";
        }

        return {
          level,
          name: levelNames[level],
          box: boxForLevel || null,
          status,
        };
      }
    );

    return levels;
  } catch (error: any) {
    console.error(error);
    return rejectWithValue(error.message || "Failed to fetch VIP boxes");
  }
});

// Update the reducer to handle the enriched data
const vipSlice = createSlice({
  name: "vip",
  initialState,

  reducers: {
    setSelectedLevelBoxWithStatus: (state, action: PayloadAction<LevelBoxWithStatus | null>) => {
      state.selectedLevelBoxWithStatus = action.payload;
    },
       // Action to update the status with one of the allowed literal values
       updateBoxStatus(state, action: PayloadAction<"locked" | "available" | "opened">) {
        if (state.selectedLevelBoxWithStatus) {
          state.selectedLevelBoxWithStatus = {
            ...state.selectedLevelBoxWithStatus,
            status: action.payload, // Make sure the payload is one of the allowed values
          };
        }
      },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchVIPStatus.pending, (state) => {
        state.fetchVIPStatus = "loading";
        state.fetchVIPError = null;
      })
      .addCase(
        fetchVIPStatus.fulfilled,
        (state, action: PayloadAction<VIPStatus>) => {
          state.fetchVIPStatus = "succeeded";
          state.vipStatus = action.payload;
        }
      )
      .addCase(fetchVIPStatus.rejected, (state, action) => {
        state.fetchVIPStatus = "failed";
        state.fetchVIPError =
          action.error.message || "Failed to fetch VIP status";
      })
      .addCase(getVipBoxes.pending, (state) => {
        state.getVipBoxesStatus = "loading";
        state.getVipBoxesError = null;
      })
      .addCase(getVipBoxes.fulfilled, (state, action) => {
        state.getVipBoxesStatus = "succeeded";
        state.levelBoxes = action.payload; // Update with enriched levelBoxes
      })
      .addCase(getVipBoxes.rejected, (state, action) => {
        state.getVipBoxesStatus = "failed";
        state.getVipBoxesError =
          action.payload ||
          action.error.message ||
          "Failed to fetch box details";
      });
  },
});

export default vipSlice.reducer;
export const { setSelectedLevelBoxWithStatus, updateBoxStatus } = vipSlice.actions;
