import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaChevronLeft, FaAngleRight } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../app/store';
import { fetchBanners } from '../features/bannersSlice';
import { fetchSettings } from '../features/settingssSlice';
import { getDefaultBoxSlidesDelay, getImagePath } from '../utils/utility';

const HeroSection = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vw / 3); /* Ensures a 3:1 aspect ratio */
`;

const CarouselContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  height: calc(100vw / 3); /* Ensures a 3:1 aspect ratio */
`;

const BannerDiv = styled.div`
  min-width: 100%;
  height: 100%; /* Ensures each banner takes the full height */
  overflow: hidden;
  flex: 0 0 100%;
`;

const BannerImage = styled.img<{ $objectFit: string }>`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => props.$objectFit};
`;

const Arrow = styled.a`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #246d6d;
  font-size: 24px;
  text-decoration: none;
  z-index: 2;
`;

const LeftArrow = styled(Arrow)`
  left: 10px;
`;

const RightArrow = styled(Arrow)`
  right: 10px;
`;

const IndicatorsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`;

const IndicatorDot = styled.div<{ $active: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.$active ? 'white' : 'gray')};
  margin: 0 5px;
`;

const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 24px;
  color: #246d6d;
`;

const HeroComponent: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState<number | undefined>(undefined);
  const [objectFit, setObjectFit] = useState<string>('cover'); // Default to cover

  const dispatch: AppDispatch = useDispatch();
  const { banners, isFetchBannersInprogress } = useSelector((state: RootState) => state.banners);
  const { settings, isFetchSettingInprogress } = useSelector((state: RootState) => state.settings);

  const totalBanners = banners.length;
  const changeInterval = getDefaultBoxSlidesDelay(settings);

  useEffect(() => {
    dispatch(fetchBanners('true'));
    dispatch(fetchSettings());
  }, [dispatch]);

  const handleLeftArrowClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalBanners) % totalBanners);
    resetTimer();
  };

  const handleRightArrowClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalBanners);
    resetTimer();
  };

  const resetTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    startTimer();
  };

  const startTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setIntervalId(setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % totalBanners);
    }, changeInterval) as any);
  };

  useEffect(() => {
    if (!isFetchBannersInprogress && !isFetchSettingInprogress && totalBanners > 0) {
      startTimer();
    }

    return () => clearInterval(intervalId);
  }, [isFetchBannersInprogress, isFetchSettingInprogress, totalBanners, changeInterval]);

  // Dynamically set the object-fit based on image dimensions
  useEffect(() => {
    if (banners.length > 0) {
      const img = new Image();
      img.src = getImagePath(banners[currentIndex].image);
      img.onload = () => {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        if (aspectRatio < 1) {
          setObjectFit('scale-down');
        } else {
          setObjectFit('cover');
        }
      };
    }
  }, [currentIndex, banners]);

  // Show loading indicator while fetching banners or settings
  if (isFetchBannersInprogress || isFetchSettingInprogress) {
    return (
      <LoadingIndicator>
        Loading...
      </LoadingIndicator>
    );
  }

  // Show the banner carousel after fetching is complete
  return (
    <HeroSection>
      <CarouselContainer style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {banners.map((banner, index) => (
          <BannerDiv key={banner.id}>
            <BannerImage
              src={ getImagePath(banner.image)}
              alt={`Banner ${index + 1}`}
              $objectFit={objectFit}
            />
          </BannerDiv>
        ))}
      </CarouselContainer>
      <LeftArrow onClick={handleLeftArrowClick}><FaChevronLeft /></LeftArrow>
      <RightArrow onClick={handleRightArrowClick}><FaAngleRight /></RightArrow>
      <IndicatorsContainer>
        {banners.map((_, index) => (
          <IndicatorDot key={index} $active={index === currentIndex} />
        ))}
      </IndicatorsContainer>
    </HeroSection>
  );
};

export default HeroComponent;