import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AppDispatch } from "../app/store";
import { useDispatch } from "react-redux";
import localization from "../services/localization";
import { FavouriteBoxes } from "./FavouriteBoxes";
import Footer from "./Footer";
import { fetchVIPStatus } from "../features/vipSlice";
import LevelProgressIndicator from "./LevelProgressIndicator";
import { BonusBoxes } from "./BonusBoxes";

const VIPPageLayout = styled.div``;

const VIPFavLabel = styled.div`
  font-family: Verdana;
  font-size: 24px;
  font-weight: normal;
  padding: 32px 16px 16px 16px;
  color: #d9fcff;
`;

const VIPDiveIntoChaosTitle = styled.h2`
  align-self: stretch;
  font-family: Verdana, sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #a1e5e5;
  margin: 0;
`;

const VIPMysteryBoxSubtitle = styled.p`
  font-family: Verdana, sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #d9ffff;
  margin: 0;
`;

const VIPDescriptionText = styled.p`
  font-family: Verdana, sans-serif;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #a1e5e5;
  margin-top: 8px;
`;

const BaseStyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.25rem;
  border: 2px solid transparent;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0.375rem;
  outline: none;
  transition: color 0.3s, border-color 0.3s;
  width: 100px;
  margin: 8px;

  &:disabled {
    border-color: transparent;
    color: white;
    background-color: #383838;
  }
`;

const VIPPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVIPStatus());
  }, []);


  return (
    <VIPPageLayout>
      <VIPDiveIntoChaosTitle>Unlock the Ultimate Mystery Box Experience</VIPDiveIntoChaosTitle>
      <VIPMysteryBoxSubtitle>Become a Boxchaos VIP!</VIPMysteryBoxSubtitle>
      <VIPDescriptionText>With every unbox, your status rises! The higher your level, the more exclusive your rewards.</VIPDescriptionText>
      <LevelProgressIndicator />

      <VIPFavLabel>{localization.bonus_boxes}</VIPFavLabel>
      <BonusBoxes />
      <Footer />
    </VIPPageLayout>
  );
};

export default VIPPage;
