// src/ProfileComponent.tsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input'; // Import react-phone-number-input component
import 'react-phone-number-input/style.css'; // Import styles for react-phone-number-input
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../app/store';
import { updatePassword, saveProfile, uploadFileAndSavePicture, resetSaveProfileStatus } from '../features/userSlice';
import { FaEye, FaEyeSlash, FaCheck, FaTimes } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { getImagePath } from '../utils/utility';
import CountrySelector, { CountryOption, getCountryOptionByLabel } from './CountrySelector';

const ScrollableContainer = styled.div`
  height: 100vh; /* Set the height of the container to full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const UserHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImgContainer = styled.div`
  cursor: pointer;
    padding: 8px 0;

`;

const UserIcon = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (max-width: 472px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FullWidthElement = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UploadBtnWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
    padding: 8px 0;

`;

const Button = styled.button`
  border: 1px solid #ccc;
  color: white;
  background-color: #4caf50;
  padding: 8px 20px;
  font-size: 16px;
  cursor: pointer;
`;

const Input = styled.input`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`;

const TextWhite = styled.p`
  color: white;
  margin: 0 10px;
`;

const UserDetailsContainer = styled.div`
  text-align: center;
`;

const Username = styled.p`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UsernameText = styled.span`
  display: inline-block;
  width: 130px;
`;

const PencilIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;

const Usermail = styled.p`
  color: white;
`;

const EmailText = styled.span`
  display: inline-block;
`;

const SectionTitleSpan = styled.span`
  width: 174px;
  height: 17px;
  font-family: Verdana;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #d9ffff;
`;

const PersonalInformationContainer = styled.div`
  width: 472px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding: 0;

  @media (max-width: 472px) {
    width: 100%;
  }
`;

const SectionHeading = styled.div`
  width: 100%;
  height: 32px;
  flex-grow: 0;
  padding: 8px 0;
  border-bottom: solid 1px #acbfbf;
`;

const PersonalInformationLabel = styled.label`
  font-family: Verdana;
  font-size: 14px;
  font-weight: bold;
  color: #d9ffff;
`;

const PersonalInformationInput = styled.input`
  width: 100%; /* Adjusting width to full */
  height: 30px;
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 14px;

  @media (max-width: 472px) {
    width: 100%;
  }
`;


const InputWrapper = styled.div`
  position: relative;
`;

const PasswordInput = styled.input`
  width: 100%;
  height: 30px;
  border: 1px solid #ccc;
  padding-right: 40px;
  padding: 4px;
  font-size: 14px;
`;

const TogglePasswordButton = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  color: #2AA298;
  font-size: 18px;

  &:focus {
    outline: none;
  }
`;

const GenderSelect = styled.select`
  width: 100%; /* Adjusting width to full */
  height: 32px;
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 14px;

  @media (max-width: 472px) {
    width: 100%;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  width: 100%; /* Adjusting width to full */
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 14px;

  @media (max-width: 472px) {
    width: 100%;
  }
`;

const RightMargin = styled.div`
  margin-right: 16px;
  width: 100%;

  @media (max-width: 472px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

const WithoutMargin = styled.div`
  width: 100%;

  @media (max-width: 472px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

// Styled component for additional vertical space
const Spacer = styled.div`
  height: 100px; /* Adjust the height as needed */
`;

const ActionButtonWrapper = styled.div`
  margin-left: auto; /* Push this div to the right */
`;

const ActionButton = styled.button`

  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center */

  border: 1px solid #a3becc; /* Add green border */

  width: 84px;
  height: 28px;
  margin-bottom: 16px;
  border-radius: 8px;

  font-family: Verdana;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #a3becc;

  background-color: transparent;


  cursor: pointer;

  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;


const ValidationList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between items */
`;

const ValidationItem = styled.li<{ isValid: boolean }>`
  color: ${props => props.isValid ? 'green' : 'red'};
  display: flex;
  align-items: center;
  flex: 1 1 auto; /* Allow items to grow based on content */
  min-width: 80px; /* Ensure that items don't get too small */
  max-width: 150px; /* Prevent them from getting too large */
  white-space: nowrap; /* Prevent the text from wrapping */
  font-size: 12px; /* Adjust the font size as needed */
`;

const LoadingIndicator = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2AA298;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


const ProfileComponent: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const userInfo = useSelector((state: RootState) => state.user.user);
  const uploadError = useSelector((state: RootState) => state.user.uploadError);
  const uploadSuccess = useSelector((state: RootState) => state.user.uploadSuccess);
  const saveProfileError = useSelector((state: RootState) => state.user.saveProfileError);
  const saveProfileStatus = useSelector((state: RootState) => state.user.saveProfileStatus);
  const updatePasswordSuccess = useSelector((state: RootState) => state.user.updatePasswordSuccess);
  const updatePasswordError = useSelector((state: RootState) => state.user.updatePasswordError);
  const updatePasswordLoading = useSelector((state: RootState) => state.user.updatePasswordLoading);


  // State for form inputs
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newRetypedPassword, setNewRetypedPassword] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false); // State to toggle password visibility
  const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle password visibility
  const [showNewRetypedPassword, setShowNewRetypedPassword] = useState(false); // State to toggle password visibility


  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(null);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');


  // Extract user picture URL
  let pic = "https://d3dvavv1qailhf.cloudfront.net/avatars/Female_2_s.png";
  if (userInfo?.picture && userInfo.picture.trim().length > 1) {
    pic = getImagePath(userInfo.picture);
  }


  useEffect(() => {
    if (userInfo) {
      setFirstName(userInfo.first_name || '');
      setLastName(userInfo.last_name || '');
      setDob(userInfo.dob || '');
      setGender(userInfo.gender || '');
      setPhone(userInfo.phone || '');
      setSelectedCountry(getCountryOptionByLabel(userInfo.country));
      setState(userInfo.state || '');
      setCity(userInfo.city || '');
      setPostalCode(userInfo.postal_code || '');
      setAddress(userInfo.street_address_1 || '');
      setAddress2(userInfo.street_address_2 || '');
    }
  }, [userInfo]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      dispatch(uploadFileAndSavePicture(file));
    }
  };

  const handleCountryChange = (selected: CountryOption | null) => {
    setSelectedCountry(selected);
    console.log("Selected country:", selected);
  };


  const handleProfileUpdate = () => {
    dispatch(saveProfile({
      first_name: firstName,
      last_name: lastName,
      picture_url: userInfo?.picture || ' ',
      dob,
      gender,
      phone,
      country: selectedCountry?.label ?? "",
      state,
      city,
      postal_code: postalCode,
      street_address_1: address,
      street_address_2: address2
    }));
  };


  const handleResetPassord = async () => {
    if (!oldPassword || !newPassword || !newRetypedPassword) {
      toast.error("All fields are required!");
      return;
    }

    if (newPassword !== newRetypedPassword) {
      toast.error("New Password and Retyped Password must match.");
      return;
    }

    if (!validationResults.length || !validationResults.uppercase || !validationResults.lowercase || !validationResults.number || !validationResults.specialChar) {
      toast.error("New Password must meet all criteria.");
      return;
    }

    try {
      await dispatch(updatePassword({ oldPassword, newPassword })).unwrap();
    } catch (error) {
      if (updatePasswordError) {
        toast.error(updatePasswordError);
      }
    }


  };


  useEffect(() => {
    if (updatePasswordSuccess) {
      resetForm();
    }
  }, [updatePasswordSuccess, dispatch]);


  useEffect(() => {
    if (saveProfileStatus === "succeeded") {
      toast("Profile updated successfully!", {
        autoClose: 3000,
        onClose: () => { },
      });
      dispatch(resetSaveProfileStatus());
    } else if (saveProfileStatus === "failed") {
      toast(`Error saving profile: ${saveProfileError}`, {
        autoClose: 3000,
        onClose: () => { },
      });
      dispatch(resetSaveProfileStatus());
    }
  }, [saveProfileStatus, saveProfileError, dispatch]);


  const resetForm = () => {
    setNewPassword('');
    setOldPassword('');
    setNewRetypedPassword('');
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowNewRetypedPassword(false);
  };

  const validatePassword = (password: string) => {
    return {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
  };

  const validationResults = validatePassword(newPassword);

  return (
    <>
      <ToastContainer />
      <ScrollableContainer>
        <UserHeaderSection>
          <ImgContainer>
            <UserIcon src={pic} alt="User Icon" />
          </ImgContainer>
          <CenteredContainer>
            <UploadBtnWrapper>
              <Button>Upload a file</Button>
              <Input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                id="file"
                onChange={handleFileChange}
              />
            </UploadBtnWrapper>
          </CenteredContainer>
          <UserDetailsContainer>
            <Username>
              <UsernameText>'{userInfo?.first_name} {userInfo?.last_name}' </UsernameText>
            </Username>
            <Usermail>
              <EmailText>{userInfo?.email}</EmailText>
            </Usermail>
          </UserDetailsContainer>

          <PersonalInformationContainer>
            <SectionHeading>
              <SectionTitleSpan>Personal Information</SectionTitleSpan>
            </SectionHeading>

            <FlexRow>
              <RightMargin>
                <PersonalInformationLabel>First Name:</PersonalInformationLabel>
                <PersonalInformationInput
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Enter your first name"
                />
              </RightMargin>
              <WithoutMargin>
                <PersonalInformationLabel>Last Name:</PersonalInformationLabel>
                <PersonalInformationInput
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Enter your last name"
                />
              </WithoutMargin>
            </FlexRow>
            <FlexRow>
              <RightMargin>
                <PersonalInformationLabel>Date of Birth:</PersonalInformationLabel>
                <PersonalInformationInput
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              </RightMargin>
              <RightMargin>
                <PersonalInformationLabel>Gender:</PersonalInformationLabel>
                <GenderSelect
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Select your gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </GenderSelect>
              </RightMargin>
              <FullWidthElement>
                <PersonalInformationLabel>Contact:</PersonalInformationLabel>
                <StyledPhoneInput
                  initialValueFormat="national"
                  country="US"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(value: any) => setPhone(value)}
                />
              </FullWidthElement>
            </FlexRow>


            <FlexRow>
              <RightMargin>
                <PersonalInformationLabel>Country:</PersonalInformationLabel>
                <CountrySelector selectedCountry={selectedCountry} handleChange={handleCountryChange} />
              </RightMargin>
              <WithoutMargin>
                <PersonalInformationLabel>State:</PersonalInformationLabel>
                <PersonalInformationInput
                  type="text"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  placeholder="Enter your state"
                />
              </WithoutMargin>
            </FlexRow>

            <FlexRow>
              <RightMargin>
                <PersonalInformationLabel>City:</PersonalInformationLabel>
                <PersonalInformationInput
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Enter your city name"
                />
              </RightMargin>
              <WithoutMargin>
                <PersonalInformationLabel>PostalCode:</PersonalInformationLabel>
                <PersonalInformationInput
                  type="text"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  placeholder="Enter postal code"
                />
              </WithoutMargin>
            </FlexRow>


            <FullWidthElement>
              <PersonalInformationLabel>Street Address:</PersonalInformationLabel>
              <InputWrapper>
                <PasswordInput
                  type="text"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </InputWrapper>
            </FullWidthElement>

            <FullWidthElement>
              <PersonalInformationLabel>Street Address 2:</PersonalInformationLabel>
              <InputWrapper>
                <PasswordInput
                  type="text"
                  placeholder="Address 2"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                />
              </InputWrapper>
            </FullWidthElement>

            <ActionButtonWrapper>
              <ActionButton
                disabled={saveProfileStatus === 'loading'}
                onClick={handleProfileUpdate}>
                {saveProfileStatus === 'loading' ? <LoadingIndicator /> : 'Update'}
              </ActionButton>
            </ActionButtonWrapper>

            <SectionHeading>
              <SectionTitleSpan>Security</SectionTitleSpan>
            </SectionHeading>

            <FullWidthElement>
              <PersonalInformationLabel>Old Password:</PersonalInformationLabel>
              <InputWrapper>
                <PasswordInput
                  type={showOldPassword ? "text" : "password"} // Toggle between text and password
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <TogglePasswordButton onClick={() => setShowOldPassword(!showOldPassword)}>
                  {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                </TogglePasswordButton>
              </InputWrapper>
            </FullWidthElement>

            <FullWidthElement>
              <PersonalInformationLabel>New Password:</PersonalInformationLabel>
              <InputWrapper>
                <PasswordInput
                  type={showNewPassword ? "text" : "password"} // Toggle between text and password
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <TogglePasswordButton onClick={() => setShowNewPassword(!showNewPassword)}>
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </TogglePasswordButton>
              </InputWrapper>
              <ValidationList>
                <ValidationItem isValid={validationResults.length}>
                  {validationResults.length ? <FaCheck /> : <FaTimes />} 8 Characters
                </ValidationItem>
                <ValidationItem isValid={validationResults.uppercase}>
                  {validationResults.uppercase ? <FaCheck /> : <FaTimes />} Uppercase
                </ValidationItem>
                <ValidationItem isValid={validationResults.lowercase}>
                  {validationResults.lowercase ? <FaCheck /> : <FaTimes />} Lowercase
                </ValidationItem>
                <ValidationItem isValid={validationResults.number}>
                  {validationResults.number ? <FaCheck /> : <FaTimes />} Number
                </ValidationItem>
                <ValidationItem isValid={validationResults.specialChar}>
                  {validationResults.specialChar ? <FaCheck /> : <FaTimes />} Special Character
                </ValidationItem>
              </ValidationList>
            </FullWidthElement>

            <FullWidthElement>
              <PersonalInformationLabel>Confirm New Password:</PersonalInformationLabel>
              <InputWrapper>
                <PasswordInput
                  type={showNewRetypedPassword ? "text" : "password"} // Toggle between text and password
                  placeholder="Confirm New Password"
                  value={newRetypedPassword}
                  onChange={(e) => setNewRetypedPassword(e.target.value)}
                />
                <TogglePasswordButton onClick={() => setShowNewRetypedPassword(!showNewRetypedPassword)}>
                  {showNewRetypedPassword ? <FaEyeSlash /> : <FaEye />}
                </TogglePasswordButton>
              </InputWrapper>
            </FullWidthElement>

            <ActionButtonWrapper>
              <ActionButton
                disabled={updatePasswordLoading}
                onClick={handleResetPassord}>
                {updatePasswordLoading ? <LoadingIndicator /> : 'Update'}
              </ActionButton>
            </ActionButtonWrapper>

          </PersonalInformationContainer>
        </UserHeaderSection>
        <Spacer />

        {/* {uploadSuccess && <div>File uploaded successfully!</div>}
      {uploadError && <div>Error uploading file: {uploadError}</div>}
      {saveProfileSuccess && <div>Profile updated successfully!</div>}
      {saveProfileError && <div>Error updating profile: {saveProfileError}</div>} */}
      </ScrollableContainer>
    </>
  );
};

export default ProfileComponent;