// src/components/Header.tsx
import React, { useState, useLayoutEffect, useEffect } from "react";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import { selectPage } from "../features/pageSlice";
import { FaBars, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import UserHeaderButton from "./UserHeaderButton";
import LanguageSelector from "./LanguageSelector";
import localization from "../services/localization";
import { ModalType, useModal } from "../context/ModalContext";

const HeaderWrapper = styled.div`
  height: 64px;
  padding: 0 32px;
  box-shadow: inset 0 -2px 2px 0 rgba(0, 170, 255, 0.4),
    inset 0 2px 2px 0 rgba(13, 21, 26, 0.6);
  background-color: #000d0c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;

  @media (max-width: 600px) {
    padding: 0 16px;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  user-select: none; /* Prevent text selection when clicking */
  transition: transform 0.2s ease; /* Add a smooth transform transition */

  &:hover {
    transform: scale(1.1); /* Scale up on hover */
  }
`;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  color: rgba(0, 170, 255, 0.4);
  cursor: pointer;
  font-size: 24px; /* Adjust the font size as needed */
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;
const LogoImage = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 16px;

  @media (max-width: 600px) {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertical centering */
  height: 100%; /* Ensure the container takes full height */
  font-family: 'BradleyHandBold', sans-serif; /* Use BradleyHandBold */
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const HeaderCenter = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

interface HeaderButtonProps {
  disabled?: boolean;
  onClick?: () => void;
}

const HeaderButton = styled.button<HeaderButtonProps>`
  background-color: transparent;
  border: none;
  color: ${({ disabled }) => (disabled ? "#808080" : "white")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin: 0 16px;
  height: 19px;
  font-family: 'Corbel', sans-serif; /* Use Corbel font */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  &:hover {
    ${({ disabled }) =>
    !disabled &&
    css`
        text-decoration: underline;
      `}
  }
`;

const SignUpButton = styled.button`
  width: 112px;
  height: 32px;
  margin: 0 16px;
  padding: 7px 25px 8px 26px;
  border-radius: 8px;
  box-shadow: inset 0 2px 3px 0 rgba(255, 255, 255, 0.5),
    inset 0 -2px 3px 0 rgba(0, 0, 0, 0.5);
  background-color: #00a4b2;
  border: none;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #996d00;
  }
`;

const LoginButton = styled.button`
  width: 112px;
  height: 32px;
  margin: 0 8px;
  padding: 7px 34px 8px;
  border-radius: 8px;
  border: solid 1px #b2fff9;
  background-color: transparent;
  color: #fff2b2;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 178, 0.2);
  }
`;

const SlidingBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00171a;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  &.show {
    transform: translateX(0);
  }
`;

const SlidingBarContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  height: 100%;
  margin-top: 16px;
`;

const SlidingBarButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

interface SlidingBarItemProps {
  isselected: string; // Change the type to string
}

const SlidingBarItem = styled.button<SlidingBarItemProps>`
  width: 80%;
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: ${({ isselected }) => (isselected === "true" ? "default" : "pointer")};
  margin: 16px 0;
  font-family: 'Corbel', sans-serif; /* Use Corbel font */
  font-size: 18px;
  position: relative;
  padding: 8px 0;
  transition: background-color 0.3s;
  border-bottom: 1px solid #26494d;

  &:hover {
    ${({ isselected }) =>
    isselected !== "true" &&
    css`
        text-decoration: underline;
      `}
  }

  ${(props) =>
    props.isselected === "true"
      ? css`
          background-color: #00a4b2;
          color: #000;
          &:after {
            content: "";
            width: calc(100% - 16px);
            height: 2px;
            background-color: var(--primary-light-blue);
            position: absolute;
            bottom: 0;
            left: 8px;
          }
        `
      : css`
          &:after {
            content: "";
            width: 0;
            height: 2px;
            background-color: transparent;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        `}

  ${(props) =>
    props.isselected === "true" &&
    css`
      cursor: default;
      &:hover {
        text-decoration: none;
      }
    `}
  
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
      &:hover {
        text-decoration: none;
      }
    `}
`;

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [showSlidingBar, setShowSlidingBar] = useState(false);
  const navigate = useNavigate();
  const { openModal } = useModal();

  const selectedPage = useSelector(
    (state: RootState) => state.page.selectedPage
  );

  const signupClick = () => {
    openModal(ModalType.SIGN_UP);
  };

  const signinClick = () => {
    openModal(ModalType.SIGN_IN);
  };

  const handlePageClick = (page: string) => {
    dispatch(selectPage(page));
    setShowSlidingBar(false); // Close the sliding bar when a page is selected
    navigate(`/${page}/`);
  };

  const toggleSlidingBar = () => {
    setShowSlidingBar(!showSlidingBar);
  };

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []); // Calculate initial width without waiting for a render

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleHeaderClick = () => {
    dispatch(selectPage("home"));
    setShowSlidingBar(false); // Close the sliding bar when a page is selected
    navigate(`/`);
  };

  const TOKEN = localStorage.getItem("token");

  const logoutUser = () => {
    localStorage.clear();
    window.location.href = "/";
    handleHeaderClick();
  };

  return (
    <>
      <HeaderWrapper>
        {windowWidth < 768 ? (
          // Show menu button on leftHeader, logo and text on centerHeader, empty rightHeader
          <>
            <HeaderLeft onClick={handleHeaderClick}>
              <LogoImage src="/logo.png" alt="Logo" />
              <HeaderText>BOXCHAOS</HeaderText>
            </HeaderLeft>
            <HeaderRight>
              <LanguageSelector />

              <MenuButton onClick={toggleSlidingBar}>
                <FaBars />
              </MenuButton>
            </HeaderRight>
          </>
        ) : (
          // Show logo and text on leftHeader, buttons on centerHeader, signup and login on rightHeader
          <>
            <HeaderLeft onClick={handleHeaderClick}>
              <LogoImage src="/logo.png" alt={localization.logo} />
              <HeaderText>BOXCHAOS</HeaderText>
            </HeaderLeft>
            <HeaderCenter>
              <HeaderButton onClick={() => handlePageClick("boxes")}>
                {localization.all_boxes}
              </HeaderButton>
              <HeaderButton
                onClick={() => (TOKEN ? handlePageClick("chaos") : undefined)}
                disabled={!TOKEN}
              >
                {localization.chaos}
              </HeaderButton>
              <HeaderButton onClick={() => handlePageClick("gifts")}>
                {localization.gifts}
              </HeaderButton>
              <HeaderButton
                onClick={() => handlePageClick("affiliates")}
                disabled={true}
              >
                {localization.affiliates}
              </HeaderButton>
              <HeaderButton
                onClick={() => handlePageClick("vips")}
                disabled={!TOKEN}
              >
                {localization.vips}
              </HeaderButton>
            </HeaderCenter>
            <HeaderRight>
              {TOKEN ? (
                <>
                  <UserHeaderButton clickAction={handlePageClick} />
                  <LanguageSelector />

                </>
              ) : (
                <>
                  <LanguageSelector />

                  <SignUpButton onClick={() => signupClick()}>
                    {localization.signup}
                  </SignUpButton>
                  <LoginButton onClick={() => signinClick()}>
                    {localization.login}
                  </LoginButton>
                </>
              )}
            </HeaderRight>
          </>
        )}
      </HeaderWrapper>
      {windowWidth < 768 && (
        <SlidingBar className={showSlidingBar ? "show" : ""}>
          <HeaderWrapper>
            <HeaderLeft>
              <LogoImage src="/logo.png" alt={localization.logo} />
              <HeaderText>BOXCHAOS</HeaderText>
            </HeaderLeft>
          </HeaderWrapper>

          <SlidingBarButton onClick={toggleSlidingBar}>
            <FaTimes />
          </SlidingBarButton>

          <SlidingBarContent>
            {/* Add menu items or links for the sliding bar */}

            {TOKEN ? (
              <SlidingBarItem
                isselected={String(selectedPage === "profile")}
                onClick={() => handlePageClick("profile")}
              >
                {localization.profile_settings}
              </SlidingBarItem>
            ) : (
              <>
                <SlidingBarItem
                  isselected={String(selectedPage === "signup")}
                  onClick={() => signupClick()}
                >
                  {localization.signup}
                </SlidingBarItem>
                <SlidingBarItem
                  isselected={String(selectedPage === "login")}
                  onClick={() => signinClick()}
                >
                  {localization.login}
                </SlidingBarItem>
              </>
            )}

            <SlidingBarItem
              isselected={String(selectedPage === "boxes")}
              onClick={() => handlePageClick("boxes")}
            >
              {localization.all_boxes}
            </SlidingBarItem>
            <SlidingBarItem
              isselected={String(selectedPage === "chaos")}
              onClick={TOKEN ? () => handlePageClick("chaos") : () => { }}
              disabled={!TOKEN}
            >
              {localization.chaos}
            </SlidingBarItem>
            <SlidingBarItem
              isselected={String(selectedPage === "gifts")}
              onClick={() => handlePageClick("gifts")}
            >
              {localization.gifts}
            </SlidingBarItem>
            <SlidingBarItem
              isselected={String(selectedPage === "affiliates")}
              onClick={() => handlePageClick("affiliates")}
              disabled={true}
            >
              {localization.affiliates}
            </SlidingBarItem>
            <SlidingBarItem
              isselected={String(selectedPage === "vips")}
              onClick={() => handlePageClick("vips")}
              disabled={true}
            >
              {localization.vips}
            </SlidingBarItem>
            {TOKEN ? (
              <SlidingBarItem
                isselected={String(selectedPage === "logout")}
                onClick={logoutUser}
              >
                {localization.logout}
              </SlidingBarItem>
            ) : (
              <></>
            )}
            {/* Add more items for other pages */}
          </SlidingBarContent>
        </SlidingBar>
      )}
    </>
  );
};

export default Header;
