import { ReplaySubject } from "rxjs";
import { Item } from "../utils/boxTypes";
// apiConfig.ts
const baseURL = "https://demo1-api.boxchaos.com/api";
export const IMAGE_BASE_PATH = "https://s3.us-east-2.amazonaws.com/demo1.boxchaos.com/";
export const returnUrlAfterLogout ="https://demo1.boxchaos.com/";

export const SLIDES_DELAY_KEY = "slides_delay"

export default baseURL

export const getWinningItemSubject = new ReplaySubject<Item>(1);