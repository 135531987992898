import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppDispatch, RootState } from '../app/store';
import { fetchCryptoSession } from '../features/cryptoSlice';

// Define a styled container for the Onramp element
const OnrampContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CryptoOnramp: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>(); // Type dispatch correctly
    const [scriptLoaded, setScriptLoaded] = useState(false); // Track script load status
    const clientSecret = useSelector((state: RootState) => state.crypto.session?.client_secret); // Get clientSecret from Redux store

    // Track whether the Stripe Onramp session has been initialized
    const sessionInitializedRef = useRef(false); // This will prevent recursive session creation
    const scriptLoadedRef = useRef(false); // This will prevent loading the script multiple times

    useEffect(() => {
        // Dispatch the async thunk to fetch the crypto session data when the component mounts
        if (!clientSecret) {
            dispatch(fetchCryptoSession());
        }

        // Function to load external scripts
        const loadStripeOnrampScript = () => {
            // Only load the script if it hasn't been loaded already
            if (!scriptLoadedRef.current) {
                const stripeScript = document.createElement('script');
                stripeScript.src = 'https://js.stripe.com/v3/';
                stripeScript.async = true;
                stripeScript.onload = () => {
                    console.log('Stripe script loaded successfully');
                };
                stripeScript.onerror = (error) => {
                    console.error('Error loading Stripe script', error);
                };
                document.head.appendChild(stripeScript);

                const cryptoScript = document.createElement('script');
                cryptoScript.src = 'https://crypto-js.stripe.com/crypto-onramp-outer.js';
                cryptoScript.async = true;
                cryptoScript.onload = () => {
                    console.log('Crypto Onramp script loaded successfully');
                    setScriptLoaded(true);
                    scriptLoadedRef.current = true; // Mark as loaded
                };
                cryptoScript.onerror = (error) => {
                    console.error('Error loading Crypto Onramp script', error);
                };
                document.head.appendChild(cryptoScript);
            }
        };

        loadStripeOnrampScript(); // Call the function to load the scripts

        return () => {
            // Clean up the script if the component unmounts
            const scripts = document.querySelectorAll('script[src="https://js.stripe.com/v3/"], script[src="https://crypto-js.stripe.com/crypto-onramp-outer.js"]');
            scripts.forEach(script => script.remove());
        };
    }, [dispatch, clientSecret]); // Add `clientSecret` to prevent unnecessary fetch

    useEffect(() => {
        // Ensure the session is created only once after the script is loaded
        if (clientSecret && scriptLoaded && !sessionInitializedRef.current) {
            if (window.StripeOnramp) {
                const stripeOnramp = window.StripeOnramp('pk_test_51H1w9kA2aL2ctbl0OHzLZMqM0x6c7fbf1CshBa3jSwtK2R8ICmhYUSYKiVOlatjEFBzmh0XMWiEKfjdGKVnoLLWY00zUfmdDPy');

                // Create and mount the Onramp session
                const onrampSession = stripeOnramp.createSession({ clientSecret });
                onrampSession.mount('#onramp-element');

                // Mark session as initialized to prevent recursive creation
                sessionInitializedRef.current = true;
            } else {
                console.error('StripeOnramp is not available on window');
            }
        }
    }, [clientSecret, scriptLoaded]); // Re-run when `clientSecret` and `scriptLoaded` change

    return (
        <OnrampContainer>
            <div id="onramp-element" style={{ width: "100%" }} />
        </OnrampContainer>
    );
};

export default CryptoOnramp;
