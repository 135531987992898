// src/components/BoxDetails.tsx
import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchBoxDetails,
  selectSelectedBox,
  selectSelectedBoxId,
  selectLoading,
  selectError,
  setBoxDetails,
  fetchItemForWinning,
  fetchItemForWinningForVipBox,
} from "../features/boxDetailsSlice";
import { AppDispatch, RootState } from "../app/store";
import styled from "styled-components";
import DynamicItems from "./DynamicItems";
import SlotMachine from "./SlotMachine/SlotMachine";
import Footer from "./Footer";
import { formatNumber, getImageSource, showToast } from "../utils/utility";
import localization from "../services/localization";
import { Item } from "../utils/boxTypes";
import { useItemDetails } from "../context/ItemDetailsContext";
import { getWinningItemSubject } from "../api/apiConfig";
import BackButton from "./BackButton";
import { useParams } from "react-router-dom";
import { setSelectedLevelBoxWithStatus, updateBoxStatus } from "../features/vipSlice";

const BoxesDetailsWrapper = styled.div`
  padding: 20px;
  color: #fff;
`;

// Styled component for additional vertical space
const Spacer = styled.div`
  height: 100px; /* Adjust the height as needed */
`;

const BoxImageColumn = styled.div`
  flex: 0 0 23%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const BoxImage = styled.img`
  width: 55%;
  height: auto;
  max-width: 100%;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width:400px) {
    position: relative;
    width: 35%;
    left: 20%;
  }
`;

const BoxName = styled.div`
  font-family: Verdana;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff7cc;
  /* margin: 10px; */
  padding: 15px;
`;

const BoxDescription = styled.div`
  font-family: Verdana;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a1e0e5;
  padding: 15px;
`;

const PriceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Price = styled.div`
  font-family: Verdana;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff7cc;
  padding-left: 15px;
`;

const TextAndCheckboxes = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;

  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  align-items: center;
`;

const ActionButton = styled.button`
  width: 142px;
  height: 40px;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  font-family: Verdana;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #00bbcc;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;

  // Default shadow effect
  box-shadow: inset 4px 8px 12px 0 rgba(255, 255, 255, 0.5),
    inset -2px -6px 10px 0 rgba(0, 0, 0, 0.6),
    0px 5px 10px rgba(0, 0, 0, 0.2);

  // Hover effect: slight scale up and darker background
  &:hover {
    background-color: #00a0b0;
    transform: scale(1.05); // Slight scale up on hover
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3); // More pronounced shadow on hover
  }

  // Active (press) effect: scale down and change shadow
  &:active {
    background-color: #008c9e;
    transform: scale(0.95); // Scale down when clicked
    box-shadow: inset 4px 6px 10px rgba(0, 0, 0, 0.8), 0px 4px 8px rgba(0, 0, 0, 0.3); // Stronger shadow inset
  }

  // Disabled state: gray background, no hover or click effect
  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
    box-shadow: none;
    color: #888;
    transform: none; // No scale effects
  }

  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

const TwoPartContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 16px;
  
  @media (max-width:400px) {
    flex-direction: column;
  }
`;

const FirstPart = styled.div`
  flex: 0 0 20%;
  /* Add your styling for the first part here */
  background-color: rgba(0, 170, 255, 0.05);
  padding-top: 16px;
`;

const SecondPart = styled.div`
  flex: 1;
  /* Add your styling for the second part here */
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 170, 255, 0.05);
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  font-family: Verdana;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a1e0e5;
`;

const ButtonSelectionButton = styled.button`
  background-color: lightgray;
  color: rgb(88, 87, 87);
  border: none;
  /* padding: 5px 10px; */
  margin: 5px;
  cursor: pointer;
  /* border-radius: 10px; */
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(255, 251, 229, 0.4);

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &.selected {
    /* background-color: #6629fa; */
    border-radius: 4px;
    border: solid 1px rgba(255, 251, 229, 0.8);
    background-color: rgba(255, 251, 229, 0.4);
    color: #ffffff;
  }
`;

const BoxDetails: React.FC = () => {
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch


  const { boxId } = useParams<{ boxId: string }>();

  // const dispatch = useDispatch();
  const selectedLevelBoxWithStatus = useSelector((state: RootState) => state.vip.selectedLevelBoxWithStatus);

  const selectedBoxId = useSelector(selectSelectedBoxId);
  const selectedBox = useSelector(selectSelectedBox);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const [itemsCount, setItemsCount] = useState(2);
  const contentRef = useRef<HTMLDivElement>(null);
  const [numColumns, setNumColumns] = useState(3);
  const [selectedSlots, setSelectedSlots] = useState(3); // Initialize with a default value


  const isLoadingWinningItem = useSelector((state: RootState) => state.boxDetails.isLoadingWinningItem);
  const balance = useSelector((state: RootState) => state.user.balance); // Replace with your balance selector



  // Create a ref to the SlotMachine component
  const slotMachineRef = useRef<HTMLButtonElement>(null);

  const handleSlotChange = (newNumColumns: SetStateAction<number>) => {
    setNumColumns(newNumColumns);
    setSelectedSlots(newNumColumns); // Update the selected state
  };


  const isBalanceIsEnough = (): boolean => {
    if (balance != null) {
      const numericBalance = parseFloat(balance);

      // Ensure numericBalance is a valid number
      if (isNaN(numericBalance)) {
        console.error("Invalid balance value.");
        return false;
      }

      const value = selectedBox?.final_value ?? 0;

      // Compare and return the result
      return numericBalance < value;
    }

    // If balance is null, return false
    return false;
  };


  // Define a function to trigger a click in the SlotMachine component
  const triggerClickInSlotMachine = () => {
    // Use the ref to access the SlotMachine component and trigger a click
    if (slotMachineRef.current) {
      // Access the button element here
      if (!!selectedBox) {
        const TOKEN = localStorage.getItem("token");

        if (isLoadingWinningItem) {
          return;
        }

        if (TOKEN != null) {

          if (isBalanceIsEnough()) {
            showToast("Not enough balance to open the box.", {
              autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
              // Other options can be added here
            });
            return;
          }

          // Dispatch the async action
          dispatch(fetchItemForWinning(selectedBox.id))
            .then((action) => {
              // Check if the payload is of the correct type
              if (fetchItemForWinning.fulfilled.match(action)) {
                const result: Item = action.payload;
                console.log(result);
                showToast(result.detail?.name + ", $" + result.detail?.price, {
                  autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
                  // Other options can be added here
                });

                getWinningItemSubject.next(result);
                slotMachineRef.current?.click();
                // Perform the next action
              } else {
                // Handle unexpected action type or payload structure
                alert("Unexpected action type or payload structure");
              }
            })
            .catch((error: string) => {
              // Handle errors if needed
              alert(error);
            });
        } else {
          slotMachineRef.current?.click();
        }
      }

      // slotMachineRef.current?.click();
    }
  };

  // Define a function to trigger a click in the SlotMachine component
  const triggerVipBoxClickInSlotMachine = () => {
    // Use the ref to access the SlotMachine component and trigger a click
    if (slotMachineRef.current) {
      // Access the button element here
      if (!!selectedBox) {
        const TOKEN = localStorage.getItem("token");

        if (isLoadingWinningItem) {
          return;
        }

        if (TOKEN != null) {

          // Dispatch the async action
          dispatch(fetchItemForWinningForVipBox(selectedBox.id))
            .then((action) => {
              // Check if the payload is of the correct type
              if (fetchItemForWinningForVipBox.fulfilled.match(action)) {
                const result: Item = action.payload;
                console.log(result);
                showToast(result.detail?.name + ", $" + result.detail?.price, {
                  autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
                  // Other options can be added here
                });

                getWinningItemSubject.next(result);
                slotMachineRef.current?.click();

                dispatch(updateBoxStatus("opened"));
                // Perform the next action
              } else {
                // Handle unexpected action type or payload structure
                alert("Unexpected action type or payload structure");
              }
            })
            .catch((error: string) => {
              // Handle errors if needed
              alert(error);
            });
        } else {
          slotMachineRef.current?.click();
        }
      }

      // slotMachineRef.current?.click();
    }
  };


  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        setItemsCount(2);
      } else if (windowWidth < 768) {
        setItemsCount(3);
      } else if (windowWidth < 1024) {
        setItemsCount(4);
      } else {
        setItemsCount(6);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight);
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  // Fetch box details when the component mounts
  useEffect(() => {
    if (selectedBoxId) {
      dispatch(fetchBoxDetails(selectedBoxId));
    } else {
      const idToUse = selectedBoxId || boxId;

      // Check if idToUse is a string before dispatching
      if (idToUse) {
        dispatch(fetchBoxDetails(idToUse));
      }

    }

        // Check if the path includes '/vip/' and perform cleanup accordingly
        const isVipPath = window.location.pathname.includes('/vips/');


    // Cleanup - reset selectedBoxId when the component unmounts
    return () => {
      dispatch(setBoxDetails(null));

      if (!isVipPath) {
        dispatch(setSelectedLevelBoxWithStatus(null));
      }
    };
  }, [dispatch, selectedBoxId]);

  if (loading) {
    return <div>{localization.loading}</div>;
  }

  if (error) {
    return <div>{localization.error} {error}</div>;
  }

  if (!selectedBox) {
    return <div>{localization.no_box_detail_available}</div>;
  }

  return (
    <BoxesDetailsWrapper ref={contentRef}>
      <SlotMachine
        numColumns={numColumns}
        numRows={3}
        box={selectedBox}
        ref={slotMachineRef}
      />
      <TwoPartContainer>
        <FirstPart>
          <BackButton />
          <BoxImageColumn>
            <BoxImage
              src={getImageSource(selectedBox.picture)}
              alt={localization.box_image}
            />
          </BoxImageColumn>
        </FirstPart>
        <SecondPart>
          <ColumnContainer>
            <BoxName>{selectedBox.name}</BoxName>
            <BoxDescription>{selectedBox.description}</BoxDescription>


            {selectedLevelBoxWithStatus == null ? (
              <Price>${formatNumber(selectedBox.final_value)}</Price>
            ) : null}

            <PriceRow>
              <TextAndCheckboxes>
                <Text>Number Of Boxes</Text>
                <Checkboxes>
                  <ButtonSelectionButton
                    className={selectedSlots === 1 ? "selected" : ""}
                    onClick={() => handleSlotChange(1)}
                  >
                    {localization.one}
                  </ButtonSelectionButton>

                  <ButtonSelectionButton
                    className={selectedSlots === 2 ? "selected" : ""}
                    onClick={() => handleSlotChange(2)}
                  >
                    {localization.two}
                  </ButtonSelectionButton>

                  <ButtonSelectionButton
                    className={selectedSlots === 3 ? "selected" : ""}
                    onClick={() => handleSlotChange(3)}
                  >
                    {localization.three}
                  </ButtonSelectionButton>

                  <ButtonSelectionButton
                    className={selectedSlots === 4 ? "selected" : ""}
                    onClick={() => handleSlotChange(4)}
                  >
                    {localization.four}
                  </ButtonSelectionButton>

                  <ButtonSelectionButton
                    className={selectedSlots === 5 ? "selected" : ""}
                    onClick={() => handleSlotChange(5)}
                  >
                    {localization.five}
                  </ButtonSelectionButton>
                </Checkboxes>

              </TextAndCheckboxes>
              {selectedLevelBoxWithStatus == null ? (
                <ActionButton disabled={selectedBox.retired === true} onClick={triggerClickInSlotMachine}>
                  {localization.open_box}
                </ActionButton>
              ) : <ActionButton disabled={selectedLevelBoxWithStatus.status === "locked" || selectedLevelBoxWithStatus.status === "opened"} onClick={triggerVipBoxClickInSlotMachine}>
                {localization.open_vip_box}
              </ActionButton>}
            </PriceRow>
          </ColumnContainer>
        </SecondPart>
      </TwoPartContainer>
      <DynamicItems itemsCount={itemsCount} box={selectedBox} />
      <Footer /> {/* Add the Footer component */}
      <Spacer /> {/* Add additional empty vertical space */}
    </BoxesDetailsWrapper>
  );
};

export default BoxDetails;

const EmailInput = styled.input`
  flex-grow: 1;
  margin-top: 8px;
  width: 100%;
  color: white;
  border-radius: 4px;
  background-color: #cfdee5;
`;

export const GifBoxDetails: React.FC = () => {
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch
  const selectedBoxId = useSelector(selectSelectedBoxId);
  const selectedBox = useSelector(selectSelectedBox);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const [itemsCount, setItemsCount] = useState(2);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        setItemsCount(2);
      } else if (windowWidth < 768) {
        setItemsCount(3);
      } else if (windowWidth < 1024) {
        setItemsCount(4);
      } else {
        setItemsCount(6);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight);
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  // Fetch box details when the component mounts
  useEffect(() => {
    if (selectedBoxId) {
      dispatch(fetchBoxDetails(selectedBoxId));
    }
    // Cleanup - reset selectedBoxId when the component unmounts
    return () => {
      dispatch(setBoxDetails(null));
    };
  }, [dispatch, selectedBoxId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!selectedBox) {
    return <div>No box details available</div>;
  }

  return (
    <BoxesDetailsWrapper ref={contentRef}>
      <TwoPartContainer>
        <FirstPart>
          <BackButton />
          <BoxImageColumn>
            <BoxImage
              src={getImageSource(selectedBox.picture)}
              alt={localization.box_image}
            />
          </BoxImageColumn>
        </FirstPart>
        <SecondPart>
          <ColumnContainer>
            <BoxName>{selectedBox.name}</BoxName>
            <BoxDescription>{selectedBox.description}</BoxDescription>
            <Price>${selectedBox.final_value}</Price>
            <PriceRow>
              <TextAndCheckboxes>
                <ColumnContainer>
                  <Text>Send this Box to</Text>
                  <EmailInput
                    type="text"
                    placeholder={localization.email_input_placeholder}
                    disabled
                  />
                </ColumnContainer>
              </TextAndCheckboxes>
              <ActionButton onClick={() => { }}>
                {localization.send}
              </ActionButton>
            </PriceRow>
          </ColumnContainer>
        </SecondPart>
      </TwoPartContainer>
      <DynamicItems itemsCount={itemsCount} box={selectedBox} />
      <Footer /> {/* Add the Footer component */}
      <Spacer /> {/* Add additional empty vertical space */}
    </BoxesDetailsWrapper>
  );
};
