import { useDispatch, useSelector } from "react-redux";
import { Box, Item } from "../utils/boxTypes";
import { AppDispatch } from "../app/store";
import { chaosBoxItemsList, fetchChaosBoxItems, isLoadingChaosItems } from "../features/chaosSlice";
import { useItemDetails } from "../context/ItemDetailsContext";
import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import deafult_box from "../assets/default_box.png";
import { AppColors } from "../theme/colors";
import { BsChevronCompactRight } from "react-icons/bs";
import localization from "../services/localization";
import { getImagePath } from "../utils/utility";



const LoadingMessage = styled.p`
  padding: 50px;
  text-align: center;
  color: #40a5c5;
`;

const BoxItemsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const BoxItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${AppColors.almostBlack};
  height: 160px;
  width:100%;
  padding-left: 8px;
  align-items: center;
  overflow-x: auto;
`;

const BoxItemImg = styled.img`
  background-color: #002233;
  object-fit: contain;
  margin: 8px 4px;
  height: 128px;
  width: 128px;
`;

interface BoxItemsProps {
    box: Box | undefined;
  }
  
export  const BoxItems: React.FC<BoxItemsProps> = ({ box }) => {
    const chaosBoxItems = useSelector(chaosBoxItemsList);
    const isLoadingBoxItems = useSelector(isLoadingChaosItems);
    const dispatch: AppDispatch = useDispatch(); // Use AppDispatch
    const { openDetails } = useItemDetails(); // Assuming you have access to the openDetails function from your context
    const boxItemsWrapperRef = useRef<null | HTMLDivElement>(null);
    const [isScrollable, setIsScrollable] = useState(false);

    // Assuming BoxItemsWrapper has a ref
    const boxItemsWrapper = boxItemsWrapperRef.current;
  
    useEffect(() => {
      const checkScrollability = () => {
        if (boxItemsWrapper) {
          const itemMargin = 4;
          const itemWidth = 128;
          const totalItems = chaosBoxItems.length;
  
          const totalWidth =
            totalItems * (itemWidth + 2 * itemMargin) - 2 * itemMargin;
  
          if (totalWidth > boxItemsWrapper.clientWidth) {
            setIsScrollable(true);
          } else {
            setIsScrollable(false);
          }
        }
      };
  
      checkScrollability();
      window.addEventListener('resize', checkScrollability);
  
      return () => {
        window.removeEventListener('resize', checkScrollability);
      };
    }, [chaosBoxItems, boxItemsWrapper]);
  
  
      
  
    const handleSetProduct = (item: Item) => {
      openDetails(item);
    };
  
    useEffect(() => {
      if (box) {
        dispatch(fetchChaosBoxItems(box.id));
      }
    }, [box]);

    const handleOnScroll = () => {
      boxItemsWrapperRef.current?.scrollBy({ behavior: 'smooth' , left : 128 });
    };
  
    function showChaosBoxItems() {
      if (isLoadingBoxItems) {
        return <LoadingMessage>Loading...</LoadingMessage>;
      } else if (chaosBoxItems.length === 0) {
        return <LoadingMessage>No Items in the Box..</LoadingMessage>;
      }
      return chaosBoxItems.map((item, index) => {
        let pic = deafult_box;
        if (item.detail?.picture) {
          pic = getImagePath(item.detail?.picture);
        }
        return (
          <BoxItemImg
            onClick={() => handleSetProduct(item)}
            key={index}
            src={pic}
            alt={localization.image}
          />
        );
      });
    }
  
    return (
      <BoxItemsDiv>
        <BoxItemsWrapper ref={boxItemsWrapperRef}>
          {!box ? (
            <LoadingMessage>{localization.no_box_selected}</LoadingMessage>
          ) : (
            showChaosBoxItems()
          )}
        </BoxItemsWrapper>
        {isScrollable && (
        <BsChevronCompactRight
          size={100}
          color="#66aacc"
          onClick={handleOnScroll}
        />
      )}
      </BoxItemsDiv>
    );
  };